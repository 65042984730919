import styled from 'styled-components';

const AddStoreContainer = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .add-outlet-form {
    padding: 2rem;
    display: grid;
  }

  .desc {
    margin: 0 auto 0.5rem auto;
    width: 28rem;
    color: #4a5568;
  }

  .form-input {
    margin: 1.5rem auto 0 auto;
    width: 28rem;
    color: #4a5568;
  }

  .form-input > .input {
    width: 28rem;
    margin-top: 0.5rem;
    height: 3.6rem;
    border: 0.1rem solid #a0aec0;
    border-radius: 0.4rem;
    color: #4a5568;
    padding: 0.7rem;
  }

  .form-input > .multi {
    height: 6.4rem;
    resize: none;
    box-sizing: border-box;
    vertical-align: top;
  }

  .location-header {
    width: 28rem;
    height: 2rem;
    margin: 1.6rem auto 0 auto; // 4px textarea created
    font-weight: bold;
    color: #2d3748;
  }

  .location-detail {
    display: flex;
    height: 5.1rem;
    margin: 0 auto;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
    color: #4a5568;

    .locate {
      height: 2rem;
      width: 2rem;
    }

    .name {
      height: 2rem;
      width: 22rem;
      margin-left: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .forward {
      height: 2rem;
      width: 2rem;
      margin-left: 1rem;
    }
  }

  .location-desc {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #718096;
  }

  .btn-container {
    width: 28rem;
    margin: auto;
    margin-top: max(2.2rem, calc(100vh - 50.2rem));
  }
`;

export default AddStoreContainer;
