import styled from 'styled-components';

const AddEvent = styled.div`
  height: 100%;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  & > .content {
    padding: 2rem;

    .event-form {
      min-height: max(43.2rem, calc(100vh - 9.2rem));
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .inputs {
        .daterange {
          width: 28rem;
          margin-top: 1.5rem;
        }
        .remark-input {
          .label {
            margin-top: 1.5rem;
            color: #4a5568;
          }

          .input {
            width: 28rem;
            margin-top: 0.5rem;
            height: 3.6rem;
            border: 0.1rem solid #a0aec0;
            border-radius: 0.4rem;
            color: #4a5568;
            padding: 0.7rem;
          }
        }
      }
    }
  }
`;

export default AddEvent;
