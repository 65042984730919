import React from 'react';

import StyledHoursSelectionBar from './index.style';

function HoursSelectionBar(props) {
  const { hours, setHours } = props;
  const nextHrCheck = hours === 1;
  const twoHrCheck = hours === 2;
  const fourHrCheck = hours === 4;

  const handleChange = (e) => {
    setHours(parseInt(e.target.value, 10));
  };
  return (
    <StyledHoursSelectionBar>
      <label htmlFor="next-hour">
        <input type="radio" name="hr-radio" id="next-hour" value={1} checked={nextHrCheck} onChange={handleChange} />
        <div>Next hour</div>
      </label>
      <label htmlFor="two-hours">
        <input type="radio" name="hr-radio" id="two-hours" value={2} checked={twoHrCheck} onChange={handleChange} />
        <div>2 hours</div>
      </label>
      <label htmlFor="four-hours">
        <input type="radio" name="hr-radio" id="four-hours" value={4} checked={fourHrCheck} onChange={handleChange} />
        <div>4 hours</div>
      </label>
    </StyledHoursSelectionBar>
  );
}

export default HoursSelectionBar;
