import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from './UserContext';
import { refreshToken } from '../../services';

function withAuth(WrappedComponent) {
  return function (props) {
    const userContext = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
      if (userContext.auth && !userContext.auth.authenticated) {
        refreshToken(userContext.auth.token)
          .then()
          .catch(() => {
            userContext.reset();
            navigate(`/account/login?redirect=${encodeURIComponent(location.pathname)}`, { replace: true });
          });
      } else if (userContext.auth && userContext.auth.authenticated) {
        refreshToken(userContext.auth.token)
          .then((res) => {
            const { token } = res.data;
            userContext.setAuth({ ...userContext.auth, token });
          })
          .catch(() => {
            userContext.reset();
            navigate(`/account/login?redirect=${encodeURIComponent(location.pathname)}`, { replace: true });
          });
      } else {
        userContext.reset();
        navigate(`/account/login?redirect=${encodeURIComponent(location.pathname)}`, { replace: true });
      }
    }, []);

    return <WrappedComponent {...props} />;
  };
}

export default withAuth;
