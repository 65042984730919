import React from 'react';

function StoreHub(props) {
  const { handleChange, sourceAdditionalInfos, disableInput } = props;

  return (
    <>
      <label className="form-input" htmlFor="username">
        <div className="label">Username</div>
        <input
          className="input"
          type="text"
          id="username"
          value={sourceAdditionalInfos.username ? sourceAdditionalInfos.username : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
      <label className="form-input" htmlFor="password">
        <div className="label">Password</div>
        <input
          className="input"
          type="password"
          id="password"
          value={sourceAdditionalInfos.password ? sourceAdditionalInfos.password : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
    </>
  );
}

export default StoreHub;
