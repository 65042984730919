import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sourcePatchService } from '../../../services';
import { UserContext, withAuth } from '../../../helper/utils';
import { icons } from '../../../assets';
import Sources from '../components/Sources';
import { Modal, CustomButton } from '../../components';
import { StoreSelection } from '../components';
import PosSettingsContainer from './index.style';

function SourceSettings() {
  const userContext = React.useContext(UserContext);

  const [sourceAdditionalInfos, setSourceAdditionalInfos] = React.useState({});
  const [sourceStore, setSourceStore] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (userContext) {
      const sourceAdditionalInfosTemp = {};
      const sourceInfo = userContext.selectedStore.source.additionalInfos;

      for (let i = 0; i < sourceInfo.length; i += 1) {
        const thisSrcInfo = sourceInfo[i];
        sourceAdditionalInfosTemp[thisSrcInfo.key] = thisSrcInfo.value;
      }

      setSourceStore({ value: userContext.selectedStore.source, label: userContext.selectedStore.source.name });
      setSourceAdditionalInfos(sourceAdditionalInfosTemp);
    }
  }, []);

  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  const handleChange = (e) => setSourceAdditionalInfos({ ...sourceAdditionalInfos, [e.target.id]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const addInfos = Object.entries(sourceAdditionalInfos).map(([k, v]) => ({ key: k, value: v }));
    addInfos[addInfos.findIndex((info) => info.key === sourceStore.value.additionalInfos[0].key)].value =
      sourceStore.value.additionalInfos[0].value;

    const source = {
      code: sourceStore.value.code,
      name: sourceStore.value.name,
      additionalInfos: addInfos,
    };

    sourcePatchService(userContext.auth.token, userContext.selectedStore.source._id, source).then(() => {
      navigate(`/${userContext.selectedStore._id}`);
    });
  };

  let renderSource = '';
  if (userContext.selectedStore.source) {
    switch (userContext.selectedStore.source.type) {
      case 'Eats365':
        renderSource = (
          <Sources.Eats365
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={false}
          />
        );
        break;
      case 'FoodMarketHubV1':
        renderSource = (
          <Sources.FoodMarketHubV1
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={false}
          />
        );
        break;
      case 'FoodMarketHubV1r5':
        renderSource = (
          <Sources.FoodMarketHubV1r5
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'FoodMarketHubV1r5PH':
        renderSource = (
          <Sources.FoodMarketHubV1r5PH
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'QsrQoreApi':
        renderSource = (
          <Sources.QsrQoreApi
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={false}
          />
        );
        break;
      case 'QsrQoreApiPH':
        renderSource = (
          <Sources.QsrQoreApiPH
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={false}
          />
        );
        break;
      case 'StoreHub':
        renderSource = (
          <Sources.StoreHub
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={false}
          />
        );
        break;
      default:
        renderSource = <div className="buffer-152px" />;
        break;
    }
  }

  return (
    <PosSettingsContainer>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">{userContext.selectedStore.source.type}</div>
      </div>
      <form className="source-form" onSubmit={onSubmit}>
        <div className="login">Login credentials</div>
        {renderSource}
        <div className="selected-store">Store selection</div>
        <div className="selected-store-detail" onClick={() => setShowModal(true)}>
          <img src={icons.map} className="map" alt="map" />
          <div className="name">{sourceStore ? sourceStore.label : null}</div>
          <img src={icons.forward} className="forward" alt="forward" />
        </div>
        <div className="btn-container">
          <CustomButton type="submit" value="SAVE" theme="default" />
        </div>
      </form>
      <Modal onClose={() => setShowModal(false)} open={showModal}>
        <StoreSelection
          onSubmitted={() => setShowModal(false)}
          onClosed={() => setShowModal(false)}
          store={userContext.selectedStore}
          sourceAdditionalInfos={sourceAdditionalInfos}
          sourceStore={sourceStore}
          setSourceStore={setSourceStore}
        />
      </Modal>
    </PosSettingsContainer>
  );
}

export default withAuth(SourceSettings);
