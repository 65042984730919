import React from 'react';
import PeriodicBarContainer from './index.style';

function PeriodicBar(props) {
  const { period, setPeriod, setChartData } = props;
  const dCheck = period === 'daily';
  const wCheck = period === 'weekly';
  const mCheck = period === 'monthly';

  const handleChange = (e) => {
    setChartData([]);
    setPeriod(e.target.value);
  };

  return (
    <PeriodicBarContainer>
      <label htmlFor="daily">
        <input type="radio" name="period-radio" id="daily" value="daily" checked={dCheck} onChange={handleChange} />
        <div>Daily</div>
      </label>
      <label htmlFor="weekly">
        <input type="radio" name="period-radio" id="weekly" value="weekly" checked={wCheck} onChange={handleChange} />
        <div>Weekly</div>
      </label>
      <label htmlFor="monthly">
        <input type="radio" name="period-radio" id="monthly" value="monthly" checked={mCheck} onChange={handleChange} />
        <div>Monthly</div>
      </label>
    </PeriodicBarContainer>
  );
}

export default PeriodicBar;
