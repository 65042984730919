import styled from 'styled-components';

const SourceSettingsContainer = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .source-form {
    padding: 2rem;
    display: flex;
    flex-direction: column;

    .login {
      width: 28rem;
      font-weight: bold;
      color: #2d3748;
    }

    > * {
      margin: 1.5rem auto 0 auto;
      width: 28rem;
      color: #4a5568;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:nth-child(2) {
      margin-top: 1.9rem;
    }

    .form-input > .input {
      width: 28rem;
      margin: 0.5rem 0 0 0;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      color: #4a5568;
      padding: 0.7rem 1rem;
    }

    .form-input > .select {
      margin: 0.5rem 0 0 0;
    }

    .buffer-137px {
      height: 13.7rem;
    }

    .buffer-76px {
      height: 7.6rem;
    }

    .selected-store {
      width: 28rem;
      height: 2rem;
      margin: 2rem auto 0 auto;
      font-weight: bold;
      color: #2d3748;
    }

    .selected-store-detail {
      display: flex;
      height: 5.1rem;
      margin: 0 auto;
      align-items: center;
      border-bottom: 0.1rem solid #edf2f7;
      color: #4a5568;
    }

    .selected-store-detail > .map {
      height: 2rem;
      width: 2rem;
    }

    .selected-store-detail > .name {
      height: 2rem;
      width: 22rem;
      margin-left: 1rem;
    }

    .selected-store-detail > .forward {
      height: 2rem;
      width: 2rem;
      margin-left: 1rem;
    }

    .btn-container {
      width: 28rem;
      margin: auto;
      margin-top: max(5.3rem, calc(100vh - 47.1rem));
    }
  }
`;

export default SourceSettingsContainer;
