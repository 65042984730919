import styled from 'styled-components';

const SalesReportsContainer = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .content {
    padding: 2rem;

    .duration-bar {
      margin-top: 1.5rem;
    }

    .title {
      margin: 2rem 0 0.5rem 0;
      font-weight: bold;
      color: #212b36;
    }

    .description {
      color: #212b36;
    }

    .chart {
      width: 28rem;
      height: 21rem;
      margin-top: 1.5rem;
    }

    .hidden {
      display: none;
    }

    .subgroup {
      width: 28rem;
      height: 10.9rem;
      margin-top: 2rem;
      border-bottom: 0.1rem solid #edf2f7;
      display: grid;
      grid-template-columns: 15rem 12rem;
      grid-template-rows: 2rem 2.8rem 2rem;
      grid-gap: 1rem;
      justify-content: space-between;

      .subgroup-title {
        height: 2rem;
        color: #212b36;
      }

      .not-accurate {
        color: #1f72c4;
        font-size: 1.2rem;
        text-align: right;
      }

      .sales {
        font-size: 2.2rem;
        line-height: 2.8rem;
        color: #212b36;
        font-weight: bold;
      }

      .text {
        font-size: 1.5rem;
        color: #007ace;
      }

      .orders {
        font-size: 1.5rem;
        color: #637381;
        text-align: right;
      }
    }

    .subgroup:last-of-type {
      border: none;
    }
  }
`;

export default SalesReportsContainer;
