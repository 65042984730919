import styled from 'styled-components';

const DetailsContainer = styled.div`
  background: #f2f2f2;
  background-image: url('../../../../assets/images/login-signup-background.png');
  background-position: center bottom;
  background-size: auto;
  background-repeat: no-repeat;
  height: 100%;

  .details-form {
    padding: 3rem;
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
  }

  .close {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }
  .content {
    display: flex;
    flex-direction: column;
    color: #4a5568;
    box-sizing: border-box;

    & > label {
      margin-top: 1.5rem;
    }

    .form-input {
      margin: 1.5rem auto 0 auto;
      width: 26rem;
      color: #4a5568;
    }

    .form-input > .input {
      width: 26rem;
      margin: 0.5rem 0 0 0;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      color: #4a5568;
      padding: 0.7rem 1rem;
    }
  }

  .btn-container {
    width: 26rem;
    margin: auto;
    margin-top: max(17.3rem, calc(100vh - 35.1rem));
  }
`;

export default DetailsContainer;
