import React from 'react';
import moment from 'moment';
import * as V from 'victory';
import _ from 'lodash';
import { icons } from '../../../../assets';

import SalesModalContainer from './index.style';

function SalesModal(props) {
  const { source, onCloseClick } = props;
  const [sale, setSale] = React.useState([]);
  const [totalSales, setTotalSales] = React.useState(null);
  const [totalOrders, setTotalOrders] = React.useState(null);
  const [dateRange, setDateRange] = React.useState({ startDate: null, endDate: null });

  React.useEffect(() => {
    if (source.length > 0) {
      setSale(source.map((d) => ({ date: d.date, sale: d.sale })));
      setTotalSales(source.reduce((a, b) => a + b.sale, 0));
      setTotalOrders(source.reduce((a, b) => a + b.order, 0));
      setDateRange({ startDate: _.min(source.map((d) => d.date)), endDate: _.max(source.map((d) => d.date)) });
    } else {
      setSale([]);
      setTotalSales(null);
      setTotalOrders(null);
      setDateRange({ startDate: null, endDate: null });
    }
  }, [source]);

  let dateRangeText = '-';
  if (sale.length > 0) {
    dateRangeText =
      sale.length === 1
        ? moment(dateRange.endDate).format('D MMM YYYY')
        : `${moment(dateRange.startDate).format('D MMM')} - ${moment(dateRange.endDate).format('D MMM YYYY')}`;
  }

  return (
    <SalesModalContainer>
      <div className="header">
        <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
        <div className="title">Sales &amp; reports</div>
      </div>
      <div className="content">
        <div className="date-range">{dateRangeText}</div>
        <div className="title">Sales</div>
        <div className="description">
          Gauge the success of your business by comparing sales across pass data, no of order, and weather.
        </div>
        <div className="chart">
          <V.VictoryChart
            domainPadding={7.5}
            minDomain={{ y: 0 }}
            singleQuadrantDomainPadding={{ x: false }}
            padding={{
              top: 8,
              bottom: 22,
              left: 0,
              right: 35,
            }}
            style={{
              background: { fill: '#ffffff' },
            }}
            height={210}
            width={280}
          >
            <V.VictoryAxis
              crossAxis={false}
              domain={7.5}
              standalone={false}
              style={{
                axis: { stroke: 'none' },
                grid: {
                  fill: 'none',
                  stroke: '#e2e8f0',
                  strokeDasharray: '5, 5',
                  strokeWidth: 1,
                },
                tickLabels: { fontSize: 12 },
              }}
              tickCount={Math.max(Math.min(sale.length, 4), 1)}
              tickFormat={(d) => {
                if (sale.length > 0) {
                  const formatted = moment(d).format('D MMM');
                  return formatted;
                }
                return d;
              }}
              tickLabelComponent={<V.VictoryLabel dy={-5} dx={10} />}
            />
            <V.VictoryAxis
              dependentAxis
              orientation="right"
              offsetX={35}
              style={{
                axis: { stroke: 'none' },
                grid: { fill: 'none', stroke: '#e2e8f0', strokeWidth: 1 },
                tickLabels: { fontSize: 12 },
              }}
              tickCount={3}
              tickLabelComponent={<V.VictoryLabel dx={-8} />}
            />
            {sale.length > 1 ? (
              <V.VictoryLine
                data={sale}
                style={{
                  data: { stroke: '#fec35d', strokeWidth: 2 },
                }}
                x={(d) => d.date}
                y={(d) => d.sale}
              />
            ) : null}
            {sale.length === 1 ? (
              <V.VictoryScatter
                data={sale}
                style={{
                  data: { fill: '#fec35d' },
                }}
                x={(d) => d.date}
                y={(d) => d.sale}
              />
            ) : null}
          </V.VictoryChart>
        </div>
        <div className="subgroup">
          <div className="subgroup-title">Forecast</div>
          <div className="sales">
            RM
            {totalSales || '-'}
          </div>
          <div>
            <V.VictoryChart height={280} width={1200}>
              <V.VictoryAxis
                crossAxis={false}
                dependentAxis
                style={{
                  axis: { stroke: 'none' },
                  grid: { fill: 'none' },
                  tickLabels: { fill: 'none' },
                }}
              />
              <V.VictoryLine
                data={sale}
                x={(d) => d.date}
                y={(d) => d.sale}
                style={{
                  data: { stroke: '#fec35d', strokeWidth: 15 },
                }}
              />
            </V.VictoryChart>
          </div>
          <div className="sales-over-time-text">Sales over time</div>
          <div className="orders">{totalOrders || '-'} orders</div>
        </div>
      </div>
    </SalesModalContainer>
  );
}

export default SalesModal;
