import React from 'react';

import icons from '../../../../assets/icons';
import HelpModalContainer from './index.style';

function HelpModal(props) {
  const { onCloseClick } = props;

  return (
    <HelpModalContainer>
      <div className="title">PAR</div>
      <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
      <div className="description">
        Periodic Automatic Replenishment Levels are minimum quantity limits that set for certain inventory item.
      </div>
      <div className="title-smart">Smart PAR levels</div>
      <div className="description">
        Dynamic PAR levels suggestion by comparing your past <span className="highlight">sales data</span>, no of{' '}
        <span className="highlight">order</span>, <span className="highlight">holiday</span> and{' '}
        <span className="highlight">weather</span>.
      </div>
    </HelpModalContainer>
  );
}

export default HelpModal;
