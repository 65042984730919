import styled from 'styled-components';

const ProcurementContainer = styled.div`
  .header {
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
    }

    .title {
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }

    .help {
      height: 2rem;
      width: 2rem;
    }
  }

  .content {
    min-height: max(47.2rem, calc(100vh - 5.2rem));
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .modal-img {
      width: 22.5rem;
      height: 10.1rem;
      margin: auto;
    }

    .purchase-order {
      .item-edit {
        display: flex;
        justify-content: space-between;
        color: #2d3748;
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid #edf2f7;

        .item {
          font-weight: bold;
        }

        .edit {
          color: #1f72c4;
        }
      }

      .order-list {
        display: flex;
        flex-direction: column;

        .order {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 1.5rem;

          .units-uom {
            text-align: right;

            .uom {
              color: #a0aec0;
              text-overflow: ellipsis;
              text-align: right;
            }
          }
        }

        .order:last-child {
          margin-bottom: 16.9rem;
        }
      }

      .btn-container {
        margin-top: 2rem;
        width: 28rem;
      }
    }
  }

  .place-order {
    width: 32rem;
    position: fixed;
    background: #fafdff;
    bottom: 0;
    padding: 2rem;
    box-shadow: 0 0.2rem 0.4rem 0.6rem rgba(24, 39, 75, 0.03);

    .summary {
      color: #4a5568;
      border-bottom: 0.1rem solid #edf2f7;
      padding-bottom: 1rem;

      .from-to-date {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #4a5568;
        font-style: italic;
        margin-bottom: 0.5rem;
      }

      .expected-sales {
        color: #2d3748;
      }

      .see-more {
        font-size: 1.2rem;
        color: #1f72c4;
      }
    }

    .btn-container {
      margin-top: 2rem;
      width: 28rem;
    }

    .export-csv {
      display: inline-block;
      width: 10rem;
      margin-top: 1rem;
      margin-left: calc((32rem - 14rem) / 2);
      text-align: center;
      color: #1f72c4;
      text-decoration-line: none;
    }
  }
`;
export default ProcurementContainer;
