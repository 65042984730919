import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton } from '../components';
import { icons } from '../../assets';
import { withAuth, UserContext } from '../../helper/utils';
import { listEvent } from '../../services';

import Add from './Add';
import Detail from './Detail';
import StyledEvent from './index.style';

function Event() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const [events, setEvents] = React.useState([]);
  const [filter, setFilter] = React.useState('');

  const onBackClick = () => navigate(`/${userContext.selectedStore._id}`);

  React.useEffect(() => {
    listEvent(userContext.auth.token, { storeId: userContext.selectedStore._id }).then((res) => {
      setEvents(res.data);
    });
  }, []);

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onAddEventClick = () => navigate('/event/add');

  const onEventClick = (id) => navigate(`/event/${id}/detail`);

  const renderEvents = events
    .filter((e) => e.name.toUpperCase().includes(filter.toUpperCase()))
    .map((e) => (
      <div key={e._id} className="event" onClick={() => onEventClick(e._id)}>
        <img src={icons.calendar} className="calendar" alt="calendar" />
        <div className="description">
          <div className="remark">{e.remark}</div>
          <div className="name">{e.name}</div>
        </div>
      </div>
    ));

  return (
    <StyledEvent>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Event</div>
      </div>
      <div className="content">
        {events.length > 0 ? (
          <div>
            <div className="filter">
              <img src={icons.search} className="search" alt="search" />
              <input type="text" placeholder="Search for event" className="filter-input" onChange={onFilterChange} />
            </div>
            {renderEvents}
          </div>
        ) : (
          <>
            <div />
            <div className="empty-list">Empty state</div>
          </>
        )}
        <div className="add-btn">
          <CustomButton type="button" value="Add" theme="default" onClick={onAddEventClick} disabled={false} />
        </div>
      </div>
    </StyledEvent>
  );
}

const authEvent = withAuth(Event);
authEvent.Add = Add;
authEvent.Detail = Detail;

export default authEvent;
export { Add, Detail };
