import React from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { newPassword } from '../../../services';
import { alert, checkmark } from '../../../assets/icons';
import { UserContext } from '../../../helper/utils';
import { SnackBar, CustomButton } from '../../components';

import NewPasswordContainer from './index.style';

function NewPassword() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = React.useState();
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    const resetPasswordToken = searchParams.get('token');
    if (!resetPasswordToken) {
      navigate('/account/login');
    }
  }, []);

  const signout = () => {
    userContext.reset();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const resetPasswordToken = searchParams.get('token');
    newPassword({ resetPasswordToken, password })
      .then((res) => {
        setSnackBarDetails({ text: res.data.message, icon: checkmark });
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setSnackBarDetails({ text: error.response.data.message, icon: alert });
        setOpenSnackBar(true);
      });
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <NewPasswordContainer>
      <div className="header">
        <div className="title">Welcome to Forecast+</div>
      </div>
      <form className="new-password-form" onSubmit={onSubmit}>
        <label className="form-input" htmlFor="name">
          <div className="label">Please enter your new password</div>
          <div className="label">New password</div>
          <input className="input" type="password" id="password" onChange={handleChange} value={password} required />
        </label>
        <div className="btn-container">
          <CustomButton type="submit" value="SAVE MY NEW PASSWORD" theme="action" />
        </div>
        <Link className="sign-out" to="/account/login" replace onClick={signout}>
          Login page
        </Link>
      </form>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </NewPasswordContainer>
  );
}

export default NewPassword;
