import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { UserContext, withAuth } from '../../../helper/utils';
import { CustomButton, DateRange, Modal, SnackBar } from '../../components';
import { Learn, UploadModal } from './components';
import { icons, templates } from '../../../assets';
import { uploadInventoryList } from '../../../services';
import ImportContainer from './index.style';

function Import() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const [csvFile, setCsvFile] = React.useState(null);
  const [uploadSuccessModal, setUploadSuccessModal] = React.useState(false);
  const [learnModal, setLearnModal] = React.useState(false);
  const [snackBarDetails, setSnackBarDetails] = React.useState({
    text: null,
    icon: null,
  });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const onBackClick = () => {
    navigate(`/${userContext.selectedStore._id}`);
  };

  const onDateChange = ([stDt, enDt]) => {
    setStartDate(stDt);
    setEndDate(enDt);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
      const file = e.dataTransfer.files[0];
      if (file.type === 'text/csv') {
        setCsvFile(file);
      } else {
        e.dataTransfer.clearData();
        setSnackBarDetails({
          text: 'Invalid file type. Only CSV type file is allowed.',
          icon: icons.alert,
        });
        setOpenSnackBar(true);
      }
    } else {
      setSnackBarDetails({
        text: 'Only one file allowed at a time.',
        icon: icons.alert,
      });
      setOpenSnackBar(true);
    }
  };

  const onClickUpload = () => {
    const file = document.getElementById('file-uploader');
    file.click();
  };

  const handleChangeUpload = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('storeId', userContext.selectedStore._id);
    formData.append('data', csvFile);
    formData.append('startDate', startDate);
    formData.append('beforeDate', moment(endDate).add(1, 'd').startOf('d').toDate());
    uploadInventoryList(userContext.auth.token, formData, 'multipart/form-data').then(() =>
      setUploadSuccessModal(true)
    );
  };

  return (
    <ImportContainer>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Import Inventory Usage</div>
      </div>
      <form className="import-form" onSubmit={onSubmit}>
        <div className="description">
          <div className="sub-title">Import your actual inventory usage for the provided date range</div>
          <div className="sub-text">
            The import file should follow the format in the template below.{' '}
            <span className="learn-more" onClick={() => setLearnModal(true)}>
              Learn more
            </span>{' '}
            how to import the file?
          </div>
          <div className="download">
            <img src={icons.download} className="download-icon" alt="downloadCSVTemplate" />
            <a href={templates.inventoryUsage} download className="download-template-text">
              Download CSV template
            </a>
          </div>
        </div>
        <div className="daterange">
          <DateRange
            labelName="Date range (Recommend 2 weeks)"
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="upload-label">Select a file to upload</div>
        <div className="drag-area" onDrop={handleDrop} onDragOver={handleDragOver}>
          {csvFile !== null ? (
            <>
              <img src={icons.csv} className="csv-icon" alt="downloadCSVTemplate" />
              <span className="csv-exist">{csvFile.name}</span>
            </>
          ) : (
            <>
              <CustomButton type="button" value="UPLOAD" theme="default" onClick={onClickUpload} />
              <span>or drop a file to upload</span>
            </>
          )}
          <input id="file-uploader" type="file" hidden accept=".csv" onChange={handleChangeUpload} />
        </div>
        <div className="btn-container">
          <CustomButton type="submit" value="IMPORT FILE" theme="action" disabled={csvFile === null} />
        </div>
      </form>
      <Modal onClose={() => setUploadSuccessModal(false)} open={uploadSuccessModal}>
        <UploadModal onSubmitted={() => navigate(`/${userContext.selectedStore._id}`)} />
      </Modal>
      <Modal onClose={() => setLearnModal(false)} open={learnModal}>
        <Learn onClosed={() => setLearnModal(false)} />
      </Modal>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </ImportContainer>
  );
}

export default withAuth(Import);
