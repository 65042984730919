import styled from 'styled-components';

const VerifyEmailContainer = styled.div`
  overflow: auto;
  .backdrop {
    position: fixed;
    background: rgba(26, 32, 44, 0.5);
    backdrop-filter: blur(0.5rem);
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
  }

  .backdrop.active {
    left: 0;
  }

  .title {
    color: #2d3748;
    margin: 2rem auto 0 auto;
    width: 28rem;
    height: 5.2rem;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 5.2rem;
  }

  .card {
    position: relative;
    margin: 5.2rem auto 0 auto;
    padding: 2rem;
    background: #ffffff;
    width: 28rem;
    height: 32.2rem;
    border: 0.1rem dashed #edf2f7;
    border-radius: 1.5rem;
    color: #2d3748;
  }

  .verify-email-img {
    position: absolute;
    height: 19.8rem;
    width: 19.8rem;
    top: -3.2rem;
    left: 4.1rem;
  }

  .desc {
    margin: 16.6rem 0 0 0;
  }

  .resend-email-form {
    .email {
      margin-top: 0.5rem;
      width: 24rem;
      height: 3.6rem;
      padding: 0.7rem 1rem;
    }
  }

  .btn-container {
    margin-top: 2rem;
    width: 24rem;
  }
`;

export default VerifyEmailContainer;
