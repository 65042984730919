import React from 'react';

function QsrQoreApi(props) {
  const { handleChange, sourceAdditionalInfos, disableInput } = props;
  return (
    <>
      <label className="form-input" htmlFor="key">
        <div className="label">Key</div>
        <input
          className="input"
          type="text"
          id="key"
          value={sourceAdditionalInfos.key ? sourceAdditionalInfos.key : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
      <label className="form-input" htmlFor="token">
        <div className="label">Token</div>
        <input
          className="input"
          type="text"
          id="token"
          value={sourceAdditionalInfos.token ? sourceAdditionalInfos.token : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
    </>
  );
}

export default QsrQoreApi;
