import React from 'react';
import { CustomButton } from '../../../../components';

import ConfirmDeleteModalContainer from './index.style';

function ConfirmDeleteModal(props) {
  const { onDelete, eventName } = props;
  const onClick = () => {
    onDelete();
  };
  return (
    <ConfirmDeleteModalContainer>
      <div className="modal-title">Are you sure?</div>
      <div className="modal-body">
        You about to delete <b>{eventName}</b> This action cannot be undone
      </div>
      <div className="btn-container">
        <CustomButton type="button" value="DELETE EVENT" theme="warning" onClick={onClick} />
      </div>
    </ConfirmDeleteModalContainer>
  );
}

export default ConfirmDeleteModal;
