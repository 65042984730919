import React from 'react';
import { UserContext } from '../../../../helper/utils';
import { icons } from '../../../../assets';
import { CustomButton, SnackBar } from '../../../components';
import { inviteMembers } from '../../../../services';

import InviteContainer from './index.style';

function Invite(props) {
  const { onClickClosedInvite } = props;
  const userContext = React.useContext(UserContext);

  const [emailList, setEmailList] = React.useState(['']);
  const [inviteLink, setInviteLink] = React.useState('');
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  React.useEffect(() => {
    const baseUrl = window.location.href.replace(location.pathname, '');
    setInviteLink(`${baseUrl}/store/${userContext.selectedStore._id}/invites`);
  }, []);

  React.useEffect(() => {
    const value = emailList[emailList.length - 1];
    if (value) {
      setEmailList([...emailList, '']);
    }
  }, [emailList]);

  const onSubmit = (e) => {
    e.preventDefault();
    const baseUrl = `${window.location.href.replace(location.pathname, '')}/store/${
      userContext.selectedStore._id
    }/invites`;

    const data = {
      storeId: userContext.selectedStore._id,
      inviterId: userContext.auth.userId,
      emailList: emailList.filter((d) => d !== ''),
      baseUrl,
    };

    inviteMembers(userContext.auth.token, data).then(() => {
      setOpenSnackBar(true);
      setSnackBarDetails({ text: 'Email invite has been sent', icon: icons.checkmark });
    });
  };

  const onCloseClick = () => onClickClosedInvite();

  const onClickCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setSnackBarDetails({ text: 'Link copy successfully!', icon: icons.checkmark });
    setOpenSnackBar(true);
  };

  const onChangeEmail = (e, index) => {
    const temp = [...emailList];
    temp[index] = e.target.value;

    setEmailList(temp);
  };

  const onClickCrossButton = (e, index) => {
    const temp = [...emailList];
    temp.splice(index, 1);
    setEmailList(temp);
  };

  const getEmailList = () =>
    emailList.map((value, index, arr) => (
      <div className="email-input" key={`input_${index}`}>
        <input
          className="input"
          type="email"
          id={`email_${index}`}
          value={value}
          onChange={(e) => onChangeEmail(e, index)}
        />
        {arr.length > 1 && index !== arr.length - 1 ? (
          <img
            className="clear-email"
            src={icons.closeRed}
            alt="clear-email"
            onClick={(e) => onClickCrossButton(e, index)}
          />
        ) : (
          ''
        )}
      </div>
    ));

  return (
    <InviteContainer emailCount={emailList.length - 1}>
      <div className="header">
        <img src={icons.close} className="close" alt="back" onClick={onCloseClick} />
        <div className="title">Invite members</div>
      </div>
      <form className="invite-form" onSubmit={onSubmit}>
        <div className="form-input-container">
          <label className="form-input form-email" htmlFor="email">
            <div className="label">Email address</div>
            <div className="email-list">{getEmailList()}</div>
          </label>
          <label className="form-input" htmlFor="email">
            <div className="label">Invite link</div>
            <input className="input" id="invite-link" value={inviteLink} disabled />
          </label>
          <div className="copy-link" onClick={onClickCopy}>
            Copy link
          </div>
        </div>
        <div id="invite-button" className="btn-container">
          <CustomButton type="submit" value="SEND INVITE" theme="default" />
        </div>
      </form>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </InviteContainer>
  );
}

export default Invite;
