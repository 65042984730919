import styled from 'styled-components';
import background from '../../../assets/images/login-signup-background.png';

const SignupContainer = styled.div`
  background: #f2f2f2;
  background-image: url(${background});
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;

  .signup-form {
    padding: 3rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title {
    color: #2d3748;
    top: 3rem;
    margin: auto;
    width: 26rem;
    font-size: 4.2rem;
    font-family: Inter;
    font-weight: bold;
    line-height: 5.2rem;
  }

  .desc {
    color: #2d3748;
    margin: auto;
    width: 26rem;
    text-decoration-line: none;
  }

  .desc > a {
    color: #1f72c4;
    text-decoration-line: none;
  }

  .form-input {
    margin: 1.5rem auto 0 auto;
    width: 26rem;
    color: #4a5568;
  }

  .form-input > .input {
    width: 26rem;
    margin: 0.5rem 0 0 0;
    height: 3.6rem;
    border: 0.1rem solid #a0aec0;
    border-radius: 0.4rem;
    color: #4a5568;
    padding: 0.7rem 1rem;
  }

  .btn-container {
    width: 26rem;
    margin: 1.5rem auto 0 auto;
  }

  .fmh-signup {
    display: inline-block;
    margin-top: max(7.9rem, calc(100vh - 44.5rem));
    margin-left: calc((100% - 26rem) / 2);
    text-align: center;
    width: 26rem;
    color: #1f72c4;
    text-decoration-line: none;
  }
`;

export default SignupContainer;
