import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../helper/utils';
import { listEvent, addEvent, addEventDateRange } from '../../../services';
import { icons } from '../../../assets';
import { CustomSelect, DateRange, CustomButton } from '../../components';
import AddEvent from './index.style';

function Add() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [eventOptions, setEventOptions] = React.useState([]);
  const [eventName, setEventName] = React.useState('');
  const [selectedEvent, setSelectedEvent] = React.useState({});
  const [openRemark, setOpenRemark] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    listEvent(userContext.auth.token, { storeId: userContext.selectedStore._id }).then((res) => {
      const result = res.data;
      const opts = result.map((s) => ({ value: s, label: s.name }));
      setEventOptions(opts);
      setIsLoading(false);
    });
  }, []);

  const onBackClick = () => {
    navigate(`/${userContext.selectedStore._id}`);
  };

  const onEventNameChanged = (inputValue) => {
    setEventName(inputValue);
  };

  const onEventChanged = (opts, meta) => {
    if (meta.action === 'create-option') {
      setSelectedEvent({
        label: opts.label,
        value: { storeId: userContext.selectedStore._id, name: opts.value, remark: '', dateRange: [] },
      });
      setOpenRemark(true);
    } else {
      setSelectedEvent(opts);
      setOpenRemark(false);
    }
  };

  const onRemarkChanged = (e) => {
    setSelectedEvent({ ...selectedEvent, value: { ...selectedEvent.value, remark: e.target.value } });
  };

  const onDateChange = ([stDt, enDt]) => {
    setStartDate(stDt);
    setEndDate(enDt);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (selectedEvent.value && selectedEvent.value._id) {
      addEventDateRange(userContext.auth.token, selectedEvent.value._id, { startDate, endDate }).then((res) => {
        navigate('/event?successfullyCreated=true');
      });
    } else {
      addEvent(userContext.auth.token, { ...selectedEvent.value, dateRange: [{ startDate, endDate }] }).then((res) => {
        navigate('/event?successfullyCreated=true');
      });
    }
  };

  return (
    <AddEvent>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Add Event</div>
      </div>
      <div className="content">
        <form className="event-form" onSubmit={onSubmit}>
          {isLoading ? (
            <div className="form-input">
              <div className="loader" />
            </div>
          ) : (
            <>
              <div className="inputs">
                <CustomSelect
                  isCreatable
                  inputId="name"
                  title="Event Name"
                  inputValue={eventName}
                  onInputChange={onEventNameChanged}
                  options={eventOptions}
                  onChange={onEventChanged}
                  value={selectedEvent}
                />
                <div className="daterange">
                  <DateRange
                    labelName="Date range"
                    onDateChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
                {openRemark ? (
                  <label className="remark-input" htmlFor="remark">
                    <div className="label">Remark (optional)</div>
                    <input
                      className="input"
                      type="text"
                      id="remark"
                      value={selectedEvent.value && selectedEvent.value.remark ? selectedEvent.value.remark : ''}
                      onChange={onRemarkChanged}
                    />
                  </label>
                ) : null}
              </div>
              <div className="add-btn">
                <CustomButton type="submit" value="Add" theme="default" disabled={false} />
              </div>
            </>
          )}
        </form>
      </div>
    </AddEvent>
  );
}

export default Add;
