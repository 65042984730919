import styled from 'styled-components';

const FiltrationModalContainer = styled.div`
  height: 100vh;
  width: 32rem;
  background: #f7fafc;
  overflow: auto;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid #edf2f7;
    padding: 0 2rem;

    .close {
      height: 2rem;
      width: 2rem;
    }

    .back {
      height: 2rem;
      width: 2rem;
    }

    .title {
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }

    .help {
      height: 2rem;
      width: 2rem;
    }
  }

  & > .content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    color: #2d3748;

    .step-description {
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #718096;
    }

    .daterange {
      margin-top: 1.5rem;
    }

    .filter {
      position: relative;
      margin-top: 2rem;

      .search {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0.8rem;
        left: 1rem;
      }

      .filter-input {
        width: 28rem;
        height: 3.6rem;
        padding: 0.7rem 1rem 0.7rem 4rem;
        color: #4a5568;
        background: #ffffff;
        border: 0.1rem solid #a0aec0;
        border-radius: 0.4rem;
      }
    }

    .inventory-list {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-content: flex-start;

      .inventory {
        width: 28rem;
        height: 5.1rem;
        display: flex;
        border-bottom: 0.1rem solid #edf2f7;
        color: #2d3748;
        justify-content: space-between;

        label {
          width: 28rem;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .checkbox {
            width: 2rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            input {
              opacity: 0;
              height: 0;
              width: 0;
            }

            input:checked + .checkmark {
              background-color: #ffc35d;
              border: none;
            }

            input:checked + .checkmark:after {
              display: block;
            }

            .checkmark:after {
              left: 0.5rem;
              top: 0.2rem;
              width: 0.3rem;
              height: 0.8rem;
              border: solid white;
              border-width: 0 0.2rem 0.2rem 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          .checkmark {
            position: relative;
            height: 1.5rem;
            width: 1.5rem;
            border: 0.2rem solid #a0aec0;
            border-radius: 0.3rem;
          }

          .checkmark:after {
            position: absolute;
            content: '';
            display: none;
          }

          .name {
            width: 25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .inventory:first-child {
        margin-top: 0;
      }

      .inventory:last-child {
        margin-bottom: 5.6rem;
      }
    }

    .cont-container {
      margin-top: max(20.7rem, calc(100vh - 31.7rem));
    }
  }

  .nxt-container {
    position: fixed;
    height: 7.6rem;
    background: #fafdff;
    padding: 2rem;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0.2rem 0.4rem 0.6rem rgba(24, 39, 75, 0.03);
  }
`;

export default FiltrationModalContainer;
