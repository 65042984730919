import styled from 'styled-components';

const StoreLocationContainer = styled.div`
  height: 100vh;
  width: 32rem;
  background: #f7fafc;

  .map {
    height: max(46.8rem, calc(100vh - 5.6rem));
    width: 32rem;
  }

  @media only screen and (min-width: 768px) and (orientation: portrait) {
    .map {
      height: max(23.4rem, calc((100vh - 5.6rem) / 2));
      width: 16rem;
      transform: scale(2);
      transform-origin: left top;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: portrait),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: portrait),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 768px) and (orientation: portrait),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: portrait),
    only screen and (min-resolution: 192dpi) and (min-width: 768px) and (orientation: portrait),
    only screen and (min-resolution: 2dppx) and (min-width: 768px) and (orientation: portrait) {
    .map {
      height: max(11.7rem, calc((100vh - 5.6rem) / 4));
      width: 8rem;
      transform: scale(4);
      transform-origin: left top;
    }
  }

  @media only screen and (min-width: 768px) and (orientation: landscape) {
    .map {
      height: max(23.4rem, calc((100vh - 5.6rem) / 2));
      width: 16rem;
      transform: scale(2);
      transform-origin: left top;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-resolution: 192dpi) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-resolution: 2dppx) and (min-width: 768px) and (orientation: landscape) {
    .map {
      height: max(11.7rem, calc((100vh - 5.6rem) / 4));
      width: 8rem;
      transform: scale(4);
      transform-origin: left top;
    }
  }

  .autocomplete {
    position: absolute;
    top: 2rem;
    left: 2rem;

    .search {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 0.8rem;
      left: 1rem;
    }

    .autocomplete-input {
      width: 28rem;
      height: 3.6rem;
      padding: 0.7rem 1rem 0.7rem 4rem;
      color: #4a5568;
      background: #ffffff;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
    }

    .autocomplete-input::placeholder {
      color: #a0aec0;
    }

    .autocomplete-dropdown-container {
      background: #ffffff;
      border-radius: 1rem;
      box-shadow: 0 0.8rem 2.8rem rgba(24, 39, 75, 0.12);
      width: 28rem;

      .suggestion-item {
        width: 28rem;
        height: 3rem;
        padding: 0.5rem 1rem;
        color: #4a5568;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .pin {
    position: absolute;
    width: 2.8rem;
    height: 3.8rem;
    top: max(19.6rem, calc((100vh - 15rem + 5.6rem + 3.8rem) / 2 - 3.8rem));
    left: 14.6rem;
  }

  .my-location {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: max(32.4rem, calc(100vh - 20rem));
    right: 2rem;
  }

  .detail {
    background: #f7fafc;
    position: absolute;
    top: max(37.4rem, calc(100vh - 15rem));
    left: 0;
    width: 32rem;
    height: 15rem;

    .address-section {
      display: flex;
      width: 32rem;
      height: 7.9rem;
      border-bottom: 0.1rem solid #edf2f7;
      padding: 1.5rem 2rem;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #4a5568;

      .locate {
        width: 2rem;
        height: 2rem;
      }

      > div {
        width: 25rem;

        .main {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
        }

        .full {
          overflow: hidden;
          max-height: 3.2rem;
        }
      }
    }

    .btn-container {
      width: 28rem;
      margin: 1.5rem 2rem;
    }
  }
`;

export default StoreLocationContainer;
