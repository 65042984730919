import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../../../services';
import { icons } from '../../../../assets';
import { CustomButton, CustomSelect } from '../../../components';
import DetailsContainer from './index.style';

const roleOpts = [
  { value: 'Kitchen staff', label: 'Kitchen staff' },
  { value: 'Managerial staffer', label: 'Managerial staffer' },
  { value: 'Floor staff', label: 'Floor staff' },
  { value: 'Bar staff', label: 'Bar staff' },
  { value: 'Delivery staff', label: 'Delivery staff' },
];

function Details({ user, setUser, handleChange, setForm }) {
  const navigate = useNavigate();
  const [redirect, setRedirect] = React.useState('');

  React.useEffect(() => {
    const url = window.location.href.replace(window.origin + window.location.pathname, '');
    setRedirect(url);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const baseUrl = `${window.location.origin}/account/verify?token=`;
    signup({ ...user, baseUrl }).then(() => {
      navigate(`/account/login${redirect}`);
    });
  };

  const onRoleSelectChange = (role) => {
    if (role) {
      setUser({ ...user, role: role.value });
    }
  };

  const onCloseClick = () => {
    setUser({
      name: '',
      email: '',
      password: '',
      companyName: '',
      role: '',
    });
    setForm('signup');
  };

  return (
    <DetailsContainer>
      <div className="header">
        <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
        <div className="title">Create an account</div>
      </div>
      <form className="details-form" onSubmit={onSubmit}>
        <div className="content">
          <label className="form-input" htmlFor="name">
            <div className="label">Your name</div>
            <input className="input" type="text" id="name" onChange={handleChange} value={user.name} required />
          </label>
          <label className="form-input" htmlFor="companyName">
            <div className="label">Company Name</div>
            <input className="input" type="text" id="companyName" onChange={handleChange} required />
          </label>
          <CustomSelect
            options={roleOpts}
            className="roleSelect"
            title="Your role in company"
            onChange={onRoleSelectChange}
            inputId="role"
          />
        </div>
        <div className="btn-container">
          <CustomButton type="submit" value="DONE" theme="action" />
        </div>
      </form>
    </DetailsContainer>
  );
}

export default Details;
