import React from 'react';
import moment from 'moment';

import { Modal, CustomButton, DateRange } from '../../../components';
import { icons } from '../../../../assets';
import HelpModal from '../HelpModal';

import FiltrationModalContainer from './index.style';

function FiltrationModal(props) {
  const { startDate, endDate, inventory, onCloseClick, onFilterSubmit } = props;
  const [step, setStep] = React.useState(1);
  const [tempStartDate, setTempStartDate] = React.useState(startDate);
  const [tempEndDate, setTempEndDate] = React.useState(endDate);
  const [tempInventory, setTempInventory] = React.useState(inventory);
  const [filter, setFilter] = React.useState('');
  const [showHelpModal, setShowHelpModal] = React.useState(false);

  const onDateRangeNext = () => {
    setStep(2);
  };

  const onBackClick = () => {
    setStep(1);
  };

  const onHelpCloseClick = () => setShowHelpModal(false);

  const onTempDateChange = ([s, e]) => {
    setTempStartDate(s);
    setTempEndDate(e);
  };

  const handleListChange = (id) => {
    const updateTempInventroy = tempInventory.map((t) => (t._id === id ? { ...t, selected: !t.selected } : t));
    setTempInventory(updateTempInventroy);
  };

  const onContinueClick = () => {
    onFilterSubmit([tempStartDate, tempEndDate, tempInventory]);
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  switch (step) {
    case 2: {
      return (
        <FiltrationModalContainer>
          <div className="header">
            <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
            <div className="title">Date range</div>
            <img src={icons.questionMark} className="help" alt="help" onClick={() => setShowHelpModal(true)} />
          </div>

          <div className="content">
            <div className="step">Step 2 of 2</div>
            <div className="step-description">Please select the date range for the items that you want to monitor</div>
            <div className="daterange">
              <DateRange
                startDate={tempStartDate}
                endDate={tempEndDate}
                onDateChange={onTempDateChange}
                minDate={moment(tempStartDate).subtract(7, 'd').toDate()}
              />
            </div>

            <div className="cont-container">
              <div className="cont-btn">
                <CustomButton
                  type="button"
                  value="CONTINUE"
                  theme="default"
                  onClick={onContinueClick}
                  disabled={!tempStartDate || !tempEndDate}
                />
              </div>
            </div>
          </div>
          <Modal onClose={onHelpCloseClick} open={showHelpModal}>
            <HelpModal onCloseClick={onHelpCloseClick} />
          </Modal>
        </FiltrationModalContainer>
      );
    }
    case 1:
    default: {
      return (
        <FiltrationModalContainer>
          <div className="header">
            <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
            <div className="title">Pick items</div>
            <img src={icons.questionMark} className="help" alt="help" onClick={() => setShowHelpModal(true)} />
          </div>
          <div className="content">
            <div className="step">Step 1 of 2</div>
            <div className="step-description">Please select the items that you want to purchase</div>
            <div className="filter">
              <img src={icons.search} className="search" alt="search" />
              <input type="text" placeholder="Search for item" className="filter-input" onChange={onFilterChange} />
            </div>
            <div className="inventory-list">
              {tempInventory
                .filter((t) => t.name.includes(filter))
                .map((t) => (
                  <div className="inventory" key={t._id}>
                    <label htmlFor={t._id}>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id={t._id}
                          name={t.name}
                          value={t._id}
                          checked={t.selected}
                          onChange={() => handleListChange(t._id)}
                        />
                        <div className="checkmark" />
                      </div>
                      <div className="name">{t.name}</div>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="nxt-container">
            <CustomButton
              type="button"
              value="NEXT"
              theme="default"
              onClick={onDateRangeNext}
              disabled={tempInventory.filter((i) => i.selected).length <= 0}
            />
          </div>
          <Modal onClose={onHelpCloseClick} open={showHelpModal}>
            <HelpModal onCloseClick={onHelpCloseClick} />
          </Modal>
        </FiltrationModalContainer>
      );
    }
  }
}

export default FiltrationModal;
