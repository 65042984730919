import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(26, 32, 44, 0.5);
  opacity: 0;
  backdrop-filter: blur(0.5rem);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .modal-content {
    overflow: auto;
    transform: translateY(10rem);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    display: none;
    z-index: 2;
  }

  ${(props) =>
    props.active
      ? `
      transition-duration: 250ms;
      transition-delay: 0ms;
      opacity: 1;

      .modal-content {
        transform: translateY(0);
        display: block;
        transition-delay: 150ms;
        transition-duration: 350ms;
      }`
      : ''}
`;

export default ModalContainer;
