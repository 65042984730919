import styled from 'styled-components';

const ImportHistoricalContainer = styled.div`
  height: 100vh;
  width: 100%;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .import-historical-form {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .description {
      padding: 2rem 2rem 0.4rem 2rem;
      border-bottom: 0.1rem solid #edf2f7;

      .sub-title {
        color: #2d3748;
      }

      .sub-text {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #718096;

        .learn-more {
          text-decoration: underline;
          color: #1f72c4;
        }
      }

      .download {
        display: flex;
        flex-direction: row;
        height: 2.1rem;
        margin-top: 1.5rem;

        .download-icon {
          width: 2rem;
          height: 2rem;
          margin: auto 1rem auto 0;
        }

        .download-template-text {
          text-decoration: none;
          margin: 0.3rem 0 0 0;
          color: #1f72c4;
        }
      }
    }

    .upload-label {
      margin: 2rem 2rem 0 2rem;
      color: #4a5568;
    }

    .drag-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 28rem;
      height: 18.2rem;
      border-radius: 0.4rem;
      border: 0.1rem dashed #a0aec0;
      margin: 0.5rem 2rem 0 2rem;

      .csv-icon {
        height: 3.9rem;
        width: 3rem;
      }

      span {
        margin-top: 1rem;
        color: #a0aec0;

        &.csv-exist {
          margin: 0;
          font-size: 1.2rem;
          line-height: 1.6rem;
          width: 8rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      input {
        width: 8.6rem;
        height: 3rem;
        padding: 0.5rem 1rem;
      }
    }

    .btn-container {
      width: 28rem;
      margin: auto;
      margin-top: max(3rem, calc(100vh - 49.4rem));
    }
  }

  .modal-container {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Black w/ opacity */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    opacity: 1;
    &.modal-container-display {
      display: flex !important;
    }
  }

  .modal-content {
    position: absolute;
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    width: 28rem;
    border-radius: 0.8rem;
    margin: auto;
    padding: 2rem;
    z-index: 99;
    left: 0;
    right: 0;
    top: 10rem;

    &.modal-confirm-display {
      display: flex !important;
    }

    .modal-title {
      font-weight: bold;
      margin: 0 0 0.6rem 0;
    }
  }
`;

export default ImportHistoricalContainer;
