import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Modal, CustomButton } from '../components';
import { dataSummary, listInventory, listInventoryUsage } from '../../services';
import { icons, images } from '../../assets';
import { withAuth, UserContext } from '../../helper/utils';

import { FiltrationModal, HelpModal, NavImportModal, SalesModal } from './components';
import Import from './Import';
import StyledProcurementContainer from './index.style';

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

function procurement() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const [inventory, setInventory] = React.useState([]);
  const [suggestPurchaseOrder, setSuggestPurchaseOrder] = React.useState([]);
  const [salesData, setSalesData] = React.useState([]);
  const [expectedSales, setExpectedSales] = React.useState(null);
  const [startDate, setStartDate] = React.useState(moment().startOf('d').toDate());
  const [endDate, setEndDate] = React.useState(null);
  const [showNavImportModal, setShowNavImportModal] = React.useState(false);
  const [showFiltrationModal, setShowFiltrationModal] = React.useState(false);
  const [showSalesModal, setShowSalesModal] = React.useState(false);
  const [showHelpModal, setShowHelpModal] = React.useState(false);

  React.useEffect(() => {
    listInventory(userContext.auth.token, { storeId: userContext.selectedStore._id }).then((res) => {
      let invs = res.data;
      if (invs.length > 0) {
        invs = invs.map((i) => ({ ...i, selected: false }));
        setInventory(invs);
        setShowFiltrationModal(true);
      } else setShowNavImportModal(true);
    });
  }, []);

  React.useEffect(() => {
    const selectedInventory = inventory.filter((i) => i.selected);
    if (selectedInventory.length > 0) {
      listInventoryUsage(userContext.auth.token, {
        storeId: userContext.selectedStore._id,
        name: selectedInventory.map((s) => s.name),
        startDate,
        beforeDate: moment(endDate).add(1, 'd').startOf('d').toDate(),
      }).then((res) => {
        setSuggestPurchaseOrder(res.data);
      });

      dataSummary(userContext.auth.token, {
        storeId: userContext.selectedStore._id,
        startDate,
        endDate: moment(endDate).add(1, 'd').startOf('d').toDate(),
        durationInterval: 'P1D',
        conditions: [
          {
            category: 'Sale',
            dataKeyName: 'sale',
            mergeData: true,
            defaultValue: 0,
            trim: true,
          },
          {
            category: 'Sale',
            dataKeyName: 'order',
            mergeData: true,
            defaultValue: 0,
            trim: true,
          },
        ],
      }).then((res) => {
        if (res.data.length > 0) {
          setSalesData(res.data);
          const sumSales = res.data.reduce((preS, s) => preS + s.sale, 0);
          setExpectedSales(sumSales);
        } else {
          setSalesData([]);
          setExpectedSales(null);
        }
      });
    }
  }, [inventory, startDate, endDate]);

  const onBackClick = () => {
    navigate(`/${userContext.selectedStore._id}`);
  };

  const onFiltrationCloseClick = () => setShowFiltrationModal(false);
  const onSalesCloseClick = () => setShowSalesModal(false);
  const onHelpCloseClick = () => setShowHelpModal(false);

  const onFilterSubmit = ([stDt, enDt, invList]) => {
    setStartDate(stDt);
    setEndDate(enDt);
    setInventory(invList);
    setShowFiltrationModal(false);
  };

  const onExportCsvClick = () => {
    const data = suggestPurchaseOrder.map((s) => ({
      name: s.name,
      quantity: s.suggestUsage,
      uom: s.unitOfMeasurement,
    }));
    if (data && data.length > 0) {
      const csv = data.reduce(
        (acc, obj) => {
          acc.push(Object.values(obj).join(','));
          return acc;
        },
        [Object.keys(data[0]).join(',')]
      );

      downloadFile({
        data: csv.join('\n'),
        fileName: `procure_${userContext.selectedStore._id}_${moment().format('YYYYMMDD')}.csv`,
        fileType: 'text/csv',
      });
    }
  };

  const renderPurchaseOrderList = suggestPurchaseOrder.map((p) => (
    <div className="order" key={p.name}>
      <div className="name">{p.name}</div>
      <div className="units-uom">
        <div className="units">{p.suggestUsage}</div>
        <div className="uom">{p.unitOfMeasurement}</div>
      </div>
    </div>
  ));

  return (
    <StyledProcurementContainer>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Smart PAR levels</div>
        <img src={icons.questionMark} className="help" alt="help" onClick={() => setShowHelpModal(true)} />
      </div>
      {suggestPurchaseOrder.length > 0 ? (
        <>
          <div className="content">
            <div className="purchase-order">
              <div className="item-edit">
                <div className="item">Items</div>
                <div className="edit" onClick={() => setShowFiltrationModal(true)}>
                  Edit
                </div>
              </div>
              <div className="order-list">{renderPurchaseOrderList}</div>
            </div>
          </div>
          <div className="place-order">
            <div className="summary">
              <div className="from-to-date">
                {moment(startDate).format('MMM Do, YYYY')} - {moment(endDate).format('MMM Do, YYYY')}
              </div>
              Expected sales: <span className="expected-sales">RM {expectedSales || '-'}</span>{' '}
              <span className="see-more" onClick={() => setShowSalesModal(true)}>
                see more
              </span>
            </div>
            <div className="btn-container">
              <CustomButton
                type="button"
                value="PLACE ORDERS"
                theme="action"
                onClick={() => setShowFiltrationModal(true)}
                disabled
              />
            </div>
            <div className="export-csv" onClick={onExportCsvClick}>
              Export CSV
            </div>
          </div>
        </>
      ) : (
        <div className="content">
          <div />
          <img className="modal-img" src={images.emptyState} alt="empty-state" />
          <div className="btn-container">
            <CustomButton
              type="button"
              value="START PROCURE"
              theme="default"
              onClick={() => setShowFiltrationModal(true)}
            />
          </div>
        </div>
      )}

      <Modal onClose={onBackClick} open={showNavImportModal}>
        <NavImportModal />
      </Modal>
      <Modal onClose={onFiltrationCloseClick} open={showFiltrationModal}>
        <FiltrationModal
          startDate={startDate}
          endDate={endDate}
          inventory={inventory}
          onCloseClick={onFiltrationCloseClick}
          onFilterSubmit={onFilterSubmit}
        />
      </Modal>
      <Modal onClose={onSalesCloseClick} open={showSalesModal}>
        <SalesModal source={salesData} onCloseClick={onSalesCloseClick} />
      </Modal>
      <Modal onClose={onHelpCloseClick} open={showHelpModal}>
        <HelpModal onCloseClick={onHelpCloseClick} />
      </Modal>
    </StyledProcurementContainer>
  );
}

const authProcurement = withAuth(procurement);
authProcurement.Import = Import;
export default authProcurement;
