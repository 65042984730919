import loginSignupBackground from './login-signup-background.png';
import newStoreSettingUp from './new-store-setting-up.png';
import newStore from './new-store.png';
import uploadSuccess from './upload-success.png';
import emailVerified from './email-verified.png';
import emptyState from './empty-state.png';

export default {
  loginSignupBackground,
  newStoreSettingUp,
  newStore,
  uploadSuccess,
  emailVerified,
  emptyState,
};

export { loginSignupBackground, newStoreSettingUp, newStore, uploadSuccess, emailVerified, emptyState };
