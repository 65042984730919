import React from 'react';
import { icons } from '../../../../../assets';
import LearnContainer from './index.style';

function Learn(props) {
  const { onClosed } = props;

  return (
    <LearnContainer>
      <div className="header">
        <img src={icons.close} className="close" alt="back" onClick={onClosed} />
        <div className="title">How To Import File</div>
      </div>
      <div className="instruction-list">
        <div className="instruction">
          <div className="circle">1</div>
          <div className="instruction-text">
            <div className="title">Prepare your file</div>
            <div className="text">Refer to the CSV template we provide.</div>
          </div>
        </div>
        <div className="instruction">
          <div className="circle">2</div>
          <div className="instruction-text">
            <div className="title">Select the date range</div>
            <div className="text">Pick the date range for the respective inventory usage</div>
          </div>
        </div>
        <div className="instruction">
          <div className="circle">3</div>
          <div className="instruction-text">
            <div className="title">Upload CSV file</div>
            <div className="text">Upload the file by the fields provided. Make sure that the file is in CSV format</div>
          </div>
        </div>
        <div className="instruction">
          <div className="circle">4</div>
          <div className="instruction-text">
            <div className="title">Successfully upload</div>
            <div className="text">Upload successfully, you may start procure for your store</div>
          </div>
        </div>
      </div>
    </LearnContainer>
  );
}

export default Learn;
