import React from 'react';
import StyledButton from './index.style';

function Button(props) {
  const { value, type, onClick, theme, disabled } = props;

  return <StyledButton theme={theme} type={type} value={value} onClick={onClick} disabled={disabled} />;
}

export default Button;
