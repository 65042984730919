import styled from 'styled-components';

const ConfirmDeleteModalContainer = styled.div`
  width: 28rem;
  min-height: 19.1rem;
  max-height: 48.4rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  overflow: auto;

  .modal-title {
    width: 24rem;
    height: 2rem;
    font-weight: bold;
    color: #2d3748;
  }

  .modal-body {
    width: 24rem;
    margin-top: 0.5rem;
    color: #4a5568;
  }

  .btn-container {
    height: 3.6rem;
    margin-top: 3rem;
  }
`;

export default ConfirmDeleteModalContainer;
