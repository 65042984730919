import styled from 'styled-components';

const HelpModalContainer = styled.div`
  width: 28rem;
  height: 27.5rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;

  .title {
    font-weight: bold;
  }

  .close {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .title-smart {
    font-weight: bold;
    margin-top: 2rem;
  }

  .description {
    margin-top: 0.5rem;
    .highlight {
      font-weight: bold;
    }
  }
`;

export default HelpModalContainer;
