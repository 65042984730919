import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import StyledSelect, { sourceSelectStyles } from './index.style';

function CustomSelect(props) {
  const { title, isDisabled, isCreatable, onChange, options, value, inputId, inputValue, onInputChange } = props;
  return (
    <StyledSelect>
      <div className="select-title">{title}</div>
      <div className="select">
        {isCreatable ? (
          <CreatableSelect
            options={options}
            styles={sourceSelectStyles}
            placeholder="Choose one"
            onChange={onChange}
            inputId={inputId}
            isDisabled={isDisabled}
            value={value}
            inputValue={inputValue}
            onInputChange={onInputChange}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        ) : (
          <Select
            options={options}
            styles={sourceSelectStyles}
            placeholder="Choose one"
            onChange={onChange}
            inputId={inputId}
            isDisabled={isDisabled}
            value={value}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        )}
      </div>
    </StyledSelect>
  );
}

export default CustomSelect;
