import React from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext, withAuth } from '../../../helper/utils';
import { CustomButton, Modal } from '../../components';
import { storePostService } from '../../../services';
import { icons } from '../../../assets';

import { AddSource, Location } from '../components';

import AddStoreContainer from './index.style';

const getAddress = (query, setAddressCallback) => {
  const cfg = {
    url: 'https://maps.googleapis.com/maps/api/geocode/json',
    method: 'get',
    params: { ...query, key: process.env.REACT_APP_GOOGLE_API_KEY },
  };

  axios(cfg).then((res) => {
    const { status, results } = res.data;
    if (status === 'OK') {
      setAddressCallback({
        main: `${results[0].address_components[0].short_name}, ${results[0].address_components[1].short_name}`,
        full: results[0].formatted_address,
      });
    }
  });
};

function Add() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const [form, setForm] = React.useState('sourceSettings');
  const [store, setStore] = React.useState({ name: '', description: '', source: null });
  const [location, setLocation] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  const [myLatLng, setMyLatLng] = React.useState(null);
  const [myAddress, setMyAddress] = React.useState(null);

  const getAddressDebounce = _.debounce(getAddress, 500);

  React.useEffect(() => {
    let mounted = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (mounted) {
          setMyLatLng({ lat: position.coords.latitude, lng: position.coords.longitude });
          getAddressDebounce(
            { latlng: `${position.coords.latitude}, ${position.coords.longitude}`, location_type: 'ROOFTOP' },
            setMyAddress
          );
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onBackClick = () => {
    setForm('sourceSettings');
  };

  const handleChange = (e) => {
    setStore({ ...store, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!location && !(myLatLng && myAddress)) console.log('missing location');
    else {
      let loc = location;
      if (!loc) loc = { ...myLatLng, address: myAddress };
      let st = {
        ...store,
        location: loc,
      };

      storePostService(userContext.auth.token, st).then((res) => {
        st = res.data;
        navigate(`/${String(st._id)}?successfullyCreated=true`);
      });
    }
  };

  switch (form) {
    case 'sourceSettings':
      return <AddSource setForm={setForm} store={store} setStore={setStore} />;
    default:
      return (
        <AddStoreContainer>
          <div className="header">
            <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
            <div className="title">Store Details</div>
          </div>
          <form className="add-outlet-form" onSubmit={onSubmit}>
            <div className="desc">Almost there! Fill in the form to complete your store with Forecast+</div>
            <label className="form-input" htmlFor="name">
              <div className="label">Store/outlet name</div>
              <input className="input" type="text" id="name" onChange={handleChange} value={store.name} required />
            </label>
            <label className="form-input" htmlFor="description">
              <div className="label">Description (optional)</div>
              <textarea className="input multi" id="description" onChange={handleChange} value={store.description} />
            </label>
            <div className="location-header">Location</div>
            <div className="location-detail" onClick={() => setShowModal(true)}>
              <img src={icons.locate} className="locate" alt="locate" />
              <div className="name">{location ? location.address.main : 'Use my current location'}</div>
              <img src={icons.forward} className="forward" alt="forward" />
            </div>
            <div className="location-desc">
              This allow us to gather weather, traffic, holiday data around your location to ensure more accurate
              forecast
            </div>
            <div className="btn-container">
              <CustomButton type="submit" value="DONE" theme="default" />
            </div>
          </form>
          <Modal onClose={() => setShowModal(false)} open={showModal}>
            <Location onSubmitted={() => setShowModal(false)} location={location} setLocation={setLocation} />
          </Modal>
        </AddStoreContainer>
      );
  }
}

export default withAuth(Add);
