import styled from 'styled-components';

const SalesModalContainer = styled.div`
  height: 100vh;
  width: 32rem;
  background: #f7fafc;
  overflow: auto;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .close {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .content {
    padding: 2rem;

    .date-range {
      margin-top: 2rem;
      width: 100%;
      text-align: center;
    }

    .title {
      margin: 2rem 0 0.5rem 0;
      font-weight: bold;
      color: #212b36;
    }

    .description {
      color: #212b36;
    }

    .chart {
      width: 28rem;
      height: 21rem;
      margin-top: 1.5rem;
    }

    .subgroup {
      width: 28rem;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 15rem 12rem;
      grid-template-rows: 2rem 2.8rem 2rem;
      grid-gap: 1rem;
      justify-content: space-between;

      .subgroup-title {
        grid-column: 1 / span 2;
        height: 2rem;
        color: #212b36;
      }

      .sales {
        font-size: 2.2rem;
        line-height: 2.8rem;
        color: #212b36;
        font-weight: bold;
      }

      .sales-over-time-text {
        font-size: 1.5rem;
        color: #007ace;
      }

      .orders {
        font-size: 1.5rem;
        color: #637381;
        text-align: right;
      }
    }
  }
`;

export default SalesModalContainer;
