import Add from './Add';
import New from './New';
import Settings from './Settings';
import SourceSettings from './SourceSettings';
import Invites from './Invites';
import ImportHistorical from './ImportHistorical';
import ExportData from './ExportData';

export default {
  Add,
  New,
  Settings,
  SourceSettings,
  Invites,
  ImportHistorical,
  ExportData,
};

export { Add, New, Settings, SourceSettings, Invites, ImportHistorical, ExportData };
