import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import _ from 'lodash';
import axios from 'axios';
import { search as searchIcon, pin, myLocation, locate } from '../../../../assets/icons';
import { CustomButton } from '../../../components';
import StoreLocationContainer from './index.style';

const googleApiLibraries = ['places'];

const mapOptions = {
  disableDefaultUI: true,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      east: 180,
      west: -180,
    },
  },
  zoom: 16,
  minZoom: 13,
  maxZoom: 18,
  gestureHandling: 'greedy',
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { featureType: 'administrative.locality', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
    { featureType: 'poi', elementType: 'labels.text', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#263c3f' }] },
    { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#6b9a76' }] },
    { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#38414e' }] },
    { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ color: '#212a37' }] },
    { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#9ca5b3' }] },
    { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#746855' }] },
    { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#1f2835' }] },
    { featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{ color: '#f3d19c' }] },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#2f3948' }] },
    { featureType: 'transit.station', elementType: 'labels.text.fill', stylers: [{ color: '#d59563' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#17263c' }] },
    { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#515c6d' }] },
    { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#17263c' }] },
  ],
};

const searchOptions = {
  // location: new google.maps.LatLng(3.1569, 101.7113),
  // radius: 2000,
  type: ['restaurant'],
};

const getAddress = (query, setAddressCallback) => {
  const cfg = {
    url: 'https://maps.googleapis.com/maps/api/geocode/json',
    method: 'get',
    params: { ...query, key: process.env.REACT_APP_GOOGLE_API_KEY },
  };

  axios(cfg).then((res) => {
    const { status, results } = res.data;
    if (status === 'OK') {
      setAddressCallback({
        main: `${results[0].address_components[0].short_name}, ${results[0].address_components[1].short_name}`,
        full: results[0].formatted_address,
      });
    }
  });
};

const getAddressDebounce = _.debounce(getAddress, 500);

function Location(props) {
  const { onSubmitted, location, setLocation } = props;
  const [search, setSearch] = React.useState('');
  const [map, setMap] = React.useState(null);
  const [currentLatLng, setCurrentLatLng] = React.useState({ lat: 3.1569, lng: 101.7113 });
  const [currentAddress, setCurrentAddress] = React.useState({ main: '', full: '' });

  React.useEffect(() => {
    let mounted = true;
    if (location) {
      const { lat, lng, address } = location;
      setCurrentLatLng({ lat, lng });
      setCurrentAddress(address);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (mounted) {
          setCurrentLatLng({ lat: position.coords.latitude, lng: position.coords.longitude });
          getAddressDebounce(
            { latlng: `${position.coords.latitude}, ${position.coords.longitude}`, location_type: 'ROOFTOP' },
            setCurrentAddress
          );
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleSearchChange = (s) => {
    setSearch(s);
  };

  const handlePlaceSelect = (address, placeId, suggestion) => {
    let s = address;
    if (suggestion) s = suggestion.formattedSuggestion.mainText;
    setSearch(s);
    setCurrentAddress({ main: s, full: address });

    if (placeId) {
      geocodeByPlaceId(placeId).then((results) => {
        const p = results[0];
        setCurrentLatLng({ lat: p.geometry.location.lat(), lng: p.geometry.location.lng() });
      });
    } else if (s) {
      geocodeByAddress(s)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => setCurrentLatLng(latLng));
    }
  };

  const onLoad = React.useCallback((mp) => {
    setMap(mp);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const onCenterChanged = () => {
    if (search) setSearch('');
    if (map && map.center) {
      setCurrentLatLng({ lat: map.center.lat(), lng: map.center.lng() });
      getAddressDebounce(
        {
          latlng: `${map.center.lat()}, ${map.center.lng()}`,
          location_type: map.zoom < 16 ? 'APPROXIMATE' : 'ROOFTOP',
        },
        setCurrentAddress
      );
    }
  };

  const onMyLocationClick = () => {
    if (search) setSearch('');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLatLng({ lat: position.coords.latitude, lng: position.coords.longitude });
        getAddressDebounce(
          { latlng: `${position.coords.latitude}, ${position.coords.longitude}`, location_type: 'ROOFTOP' },
          setCurrentAddress
        );
      });
    }
  };

  const onSaveClick = () => {
    setLocation({ ...currentLatLng, address: currentAddress });
    onSubmitted();
  };

  return (
    <StoreLocationContainer>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={googleApiLibraries}>
        <GoogleMap
          center={currentLatLng}
          options={mapOptions}
          mapContainerClassName="map"
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={onCenterChanged}
          onZoomChanged={onCenterChanged}
        />
        <PlacesAutocomplete
          value={search}
          debounce={500}
          searchOptions={searchOptions}
          onChange={handleSearchChange}
          onSelect={handlePlaceSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="autocomplete">
              <img src={searchIcon} className="search" alt="search" />
              <input {...getInputProps({ placeholder: 'Search via google map', className: 'autocomplete-input' })} />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div {...getSuggestionItemProps(suggestion, { className: 'suggestion-item' })}>
                    <span key={suggestion.id}>{suggestion.formattedSuggestion.mainText}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </LoadScript>
      <img src={pin} className="pin" alt="pin" />
      <img src={myLocation} className="my-location" alt="myLocation" onClick={onMyLocationClick} />
      <div className="detail">
        <div className="address-section">
          <img src={locate} className="locate" alt="locate" />
          <div>
            <div className="main">{currentAddress.main ? currentAddress.main : 'No data'}</div>
            <div className="full">{currentAddress.full ? currentAddress.full : ''}</div>
          </div>
        </div>
        <div className="btn-container">
          <CustomButton type="button" value="SAVE" theme="default" onClick={onSaveClick} />
        </div>
      </div>
    </StoreLocationContainer>
  );
}

export default Location;
