import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { newStore } from '../../../assets/images';
import { CustomButton } from '../../components';

import { UserContext, withAuth } from '../../../helper/utils';
import NewStoreContainer from './index.style';

function New() {
  const userContext = React.useContext(UserContext);

  const navigate = useNavigate();
  const onClick = () => {
    navigate('/store/add');
  };

  const signout = () => {
    userContext.reset();
  };

  return (
    <NewStoreContainer>
      <div className="title">FORECAS+</div>
      <div className="card">
        <img className="new-store-img" src={newStore} alt="new-store" />
        <div className="desc">Create your first store to start forecast your sales with our AI based analytics</div>
        <div className="btn-container">
          <CustomButton type="submit" value="ADD STORE/OUTLET" theme="action" onClick={onClick} />
        </div>
      </div>
      <Link className="signout" to="/account/login" replace onClick={signout}>
        Sign out now
      </Link>
    </NewStoreContainer>
  );
}

export default withAuth(New);
