import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { calendar } from '../../../assets/icons';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangePicker from './index.style';

function DateRange(props) {
  const { labelName = 'Date range', onDateChange, startDate, endDate, minDate = undefined } = props;
  const [stDate, setStDate] = React.useState(startDate);
  const [enDate, setEnDate] = React.useState(endDate);

  const onStartDateChange = (dt) => {
    const stDt = dt;
    let enDt = enDate;
    setStDate(dt);
    if (moment(enDate).isBefore(moment(dt))) {
      enDt = dt;
      setEnDate(dt);
    }
    onDateChange([stDt, enDt]);
  };
  const onEndDateChange = (dt) => {
    setEnDate(dt);
    onDateChange([stDate, dt]);
  };

  const DatePickerWithIcon = React.forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker" onClick={onClick}>
      <div className="control">
        <img src={calendar} className="calendar" alt="search" />
        <input type="text" className="date-input" ref={ref} value={value} />
      </div>
    </div>
  ));

  return (
    <DateRangePicker htmlFor="date">
      <div className="label">{labelName}</div>
      <div className="content">
        <DatePicker
          id="date"
          dateFormat="yyyy/MM/dd"
          selected={stDate}
          onChange={onStartDateChange}
          selectsStart
          startDate={stDate}
          endDate={enDate}
          minDate={minDate}
          customInput={<DatePickerWithIcon />}
        />
        <DatePicker
          dateFormat="yyyy/MM/dd"
          selected={enDate}
          onChange={onEndDateChange}
          selectsEnd
          startDate={stDate}
          endDate={enDate}
          minDate={stDate}
          customInput={<DatePickerWithIcon />}
        />
      </div>
    </DateRangePicker>
  );
}

export default DateRange;
