import React from 'react';

const UserContext = React.createContext();

function UserContextProvider({ children }) {
  const defaultVal = {
    auth: {
      authenticated: false,
      token: '',
      userId: '',
    },
    selectedStore: null,
  };

  const item = window.localStorage.getItem('userContext');
  const existedUserContext = item ? JSON.parse(item) : defaultVal;

  const [userContext, setUserContext] = React.useState(existedUserContext);

  const [auth, setAuth] = React.useState(existedUserContext.auth);
  const [selectedStore, setSelectedStore] = React.useState(existedUserContext.selectedStore);

  const reset = () => {
    setUserContext(defaultVal);
    setAuth(defaultVal.auth);
    setSelectedStore(defaultVal.selectedStore);
  };

  React.useEffect(() => {
    window.localStorage.setItem('userContext', JSON.stringify(userContext));
  }, [userContext]);

  React.useEffect(() => {
    setUserContext({ ...userContext, auth });
  }, [auth]);

  React.useEffect(() => {
    setUserContext({ ...userContext, selectedStore });
  }, [selectedStore]);

  return (
    <UserContext.Provider
      value={{
        auth,
        selectedStore,
        setAuth,
        setSelectedStore,
        reset,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
export { UserContextProvider };
