import styled from 'styled-components';

const Detail = styled.div`
  height: 100%;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
  }

  .back {
    height: 2rem;
    width: 2rem;
  }

  .title {
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .delete {
    height: 2rem;
    width: 2rem;
  }

  & > .content {
    padding: 2rem;

    .event-form {
      min-height: max(43.2rem, calc(100vh - 9.2rem));
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .inputs {
        label {
          .label {
            margin-top: 1.5rem;
            color: #4a5568;
          }

          &.name > .label {
            margin-top: 0;
          }

          .input {
            width: 28rem;
            margin-top: 0.5rem;
            height: 3.6rem;
            border: 0.1rem solid #a0aec0;
            border-radius: 0.4rem;
            color: #4a5568;
            padding: 0.7rem;
          }

          .date-range-list {
            .date-range {
              position: relative;
              margin-top: 1rem;

              .clear-date-range {
                position: absolute;
                color: red;
                top: 0.8rem;
                right: 0.8rem;
                height: 2rem;
              }
            }
          }
        }
      }

      .save-btn {
        margin-top: 2rem;
      }
    }
  }
`;

export default Detail;
