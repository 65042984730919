import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { images } from '../../../../assets';
import { CustomButton } from '../../../components';
import { UserContext } from '../../../../helper/utils';
import NavImportModalContainer from './index.style';

function NavImportModal() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const onClick = () => {
    navigate('/procurement/import');
  };
  return (
    <NavImportModalContainer>
      <div className="modal-title">Inventory usage not found</div>
      <div className="modal-body">Opps... Looks like your integration does not provide inventory usage. Import now</div>
      <img className="modal-img" src={images.emptyState} alt="empty-state" />
      <div className="btn-container">
        <CustomButton type="button" value="IMPORT" theme="default" onClick={onClick} />
      </div>
      <Link className="back" to={`/${userContext.selectedStore._id}`}>
        Back
      </Link>
    </NavImportModalContainer>
  );
}

export default NavImportModal;
