import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './helper/routes';
import { UserContextProvider } from './helper/utils/UserContext';

import GlobalStyle from './global.style';
import { useHotJar } from './helper/utils';

function Index() {
  const routing = useRoutes(routes);
  useHotJar();

  return (
    <UserContextProvider>
      <GlobalStyle />
      <div>{routing}</div>
    </UserContextProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Index />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
