import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as V from 'victory';

import { icons } from '../../../assets';
import { UserContext } from '../../../helper/utils';
import { dataSummary, sourceStoreMenuItem, storeKitchenItem } from '../../../services';
import StyledDetail from './index.style';

const roundUp = (number) => {
  const numStr = String(parseInt(number * 1.1, 10));
  let result = String(parseInt(numStr[0], 10) + 1);
  while (result.length < numStr.length) result += '0';
  return parseInt(result, 10);
};

function Detail() {
  const { name } = useParams();
  const navigate = useNavigate();

  const userContext = React.useContext(UserContext);
  const [kitchenItem, setKitchenItem] = React.useState(null);
  const [allMenuItems, setAllMenuItems] = React.useState([]);
  const [sales, setSales] = React.useState([]);

  React.useEffect(() => {
    storeKitchenItem(userContext.auth.token, { storeId: userContext.selectedStore._id }).then((res) => {
      const allKitchenItems = res.data;
      const kItem = allKitchenItems.find((k) => k.name === name);
      if (kItem) setKitchenItem(kItem);
    });
  }, [name]);

  React.useEffect(() => {
    if (kitchenItem) {
      sourceStoreMenuItem(userContext.auth.token, { sourceId: userContext.selectedStore.source._id })
        .then((res) => {
          setAllMenuItems(res.data);
        })
        .catch(() => {
          const allMItems = kitchenItem.menuItems.map((m) => ({ code: m.code, name: m.code }));
          setAllMenuItems(allMItems);
        });
    }
  }, [kitchenItem]);

  React.useEffect(() => {
    if (kitchenItem) {
      const data = {
        storeId: userContext.selectedStore._id,
        startDate: moment().startOf('d').toDate(),
        endDate: moment().add(1, 'd').startOf('d').toDate(),
        durationInterval: 'PT1H',
        conditions: [
          {
            category: 'KitchenItem',
            dataKeyName: kitchenItem.name,
            mergeData: true,
            defaultValue: 0,
          },
        ],
      };

      dataSummary(userContext.auth.token, data).then((res) => {
        setSales(res.data);
      });
    }
  }, [kitchenItem]);

  const onBackClick = () => navigate(-1);

  const getYTicks = () => {
    const maxSales = sales.reduce((a, b) => (b[name] > a ? b[name] : a), 0);
    const upperLimit = roundUp(maxSales);
    return [0, upperLimit / 2, upperLimit];
  };

  let readableMenuItems = [];

  if (kitchenItem && allMenuItems) {
    readableMenuItems = kitchenItem.menuItems.map((m) => {
      const result = { name: m.code, units: m.units };
      const mItem = allMenuItems.find((i) => i.code === m.code);
      if (mItem) result.name = mItem.name;
      return result;
    });
  }

  const renderMenuItems = readableMenuItems.map((m) => (
    <div className="menu-item" key={m.name}>
      {m.name}
    </div>
  ));

  return (
    <StyledDetail>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">{name}</div>
      </div>
      <div className="content">
        <div className="today-forecast">Today forecast</div>
        {kitchenItem && sales.length > 0 ? (
          <>
            <div className="total-sales">
              <div className="sales">{sales.reduce((acc, obj) => acc + obj[name], 0)}</div>
              <div className="units">
                &nbsp;total
                {kitchenItem.unitOfMeasurement}
              </div>
            </div>
            <div className="graph">
              <V.VictoryChart
                width={280}
                height={210}
                padding={{
                  top: 8,
                  bottom: 22,
                  left: 0,
                  right: 35,
                }}
                style={{
                  background: { fill: '#ffffff' },
                }}
              >
                <V.VictoryLine
                  data={sales.map((v, idx) => ({ date: idx, sales: v[name] }))}
                  x="date"
                  y="sales"
                  style={{
                    data: { stroke: '#02C2BD', strokeWidth: 2 },
                  }}
                />
                <V.VictoryAxis
                  crossAxis={false}
                  standalone={false}
                  style={{
                    axis: { stroke: 'none' },
                    grid: {
                      fill: 'none',
                      stroke: '#e2e8f0',
                      strokeDasharray: '5,10',
                      strokeWidth: 1,
                    },
                    tickLabels: { fontSize: 12, color: '#718096' },
                  }}
                  domain={[0, 23]}
                  tickValues={[0, 6, 12, 18]}
                  tickCount={4}
                  tickFormat={(d) => {
                    let result = '';
                    switch (d) {
                      case 0:
                        result = '12AM';
                        break;
                      case 6:
                      case 18:
                        result = '6';
                        break;
                      case 12:
                        result = '12PM';
                        break;
                      default:
                        result = d;
                        break;
                    }
                    return result;
                  }}
                  tickLabelComponent={<V.VictoryLabel textAnchor="start" />}
                />
                <V.VictoryAxis
                  crossAxis={false}
                  dependentAxis
                  orientation="right"
                  style={{
                    axis: { stroke: 'none' },
                    grid: { fill: 'none', stroke: '#e2e8f0', strokeWidth: 1 },
                    tickLabels: { fontSize: 12, color: '#718096' },
                  }}
                  tickValues={getYTicks()}
                />
              </V.VictoryChart>
            </div>
            <div className="menu-items">{renderMenuItems}</div>
          </>
        ) : null}
      </div>
    </StyledDetail>
  );
}

export default Detail;
