import React from 'react';
import axios from 'axios';
import { UserContext, withAuth } from '../../../../helper/utils';
import { sourceStoreService } from '../../../../services';
import { icons } from '../../../../assets';
import { CustomButton, CustomSelect } from '../../../components';
import SourceStoreSelectionContainer from './index.style';

function StoreSelection(props) {
  const { onSubmitted, onClosed, onError, store, sourceAdditionalInfos, sourceStore, setSourceStore } = props;

  const userContext = React.useContext(UserContext);

  const [loadingSourceStores, setLoadingSourceStores] = React.useState(true);
  const [opts, setOpts] = React.useState([]);
  const [selectedOpt, setSelectedOpt] = React.useState(null);

  const onCloseClick = () => {
    onClosed();
  };

  React.useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();
    sourceStoreService(userContext.auth.token, { type: store.source.type, ...sourceAdditionalInfos })
      .then((res) => {
        if (!unmounted) {
          const result = res.data;
          const thisOpts = result.map((s) => ({ value: s, label: s.name }));
          setOpts(thisOpts);
          if (sourceStore) setSelectedOpt(sourceStore);
          setLoadingSourceStores(false);
        }
      })
      .catch(() => {
        if (!unmounted) {
          onError();
          onClosed();
        }
      });

    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [userContext.auth, store, sourceAdditionalInfos, sourceStore]);

  const onSelectChange = (opt) => {
    setSelectedOpt(opt);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSourceStore(selectedOpt);
    onSubmitted();
  };

  return (
    <SourceStoreSelectionContainer>
      <div className="header">
        <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
        <div className="title">Store Selection</div>
      </div>
      <form className="select-source-store-form" onSubmit={onSubmit}>
        {loadingSourceStores ? (
          <div className="form-input">
            <div className="loader" />
          </div>
        ) : (
          <div className="form-input">
            <CustomSelect
              options={opts}
              title="Store selection"
              onChange={onSelectChange}
              inputValue
              inputId="store"
              value={selectedOpt}
              isDisabled={false}
            />
            <div className="btn-container">
              <CustomButton type="submit" value="SAVE" theme="default" />
            </div>
          </div>
        )}
      </form>
    </SourceStoreSelectionContainer>
  );
}

export default withAuth(StoreSelection);
