import styled from 'styled-components';

const MenuSelection = styled.div`
  height: 100vh;
  width: 100%;
  background: #f7fafc;
  overflow: auto;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .close {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .menu-selection-form {
    padding: 2rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .menu-item {
      width: 28rem;
      height: 5.1rem;
      display: flex;
      border-bottom: 0.1rem solid #edf2f7;
      color: #2d3748;
      justify-content: space-between;

      label {
        width: 28rem;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .checkbox {
          width: 2rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            opacity: 0;
            height: 0;
            width: 0;
          }

          input:checked + .checkmark {
            background-color: #ffc35d;
            border: none;
          }

          input:checked + .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 0.5rem;
            top: 0.2rem;
            width: 0.3rem;
            height: 0.8rem;
            border: solid white;
            border-width: 0 0.2rem 0.2rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        .checkmark {
          position: relative;
          height: 1.5rem;
          width: 1.5rem;
          border: 0.2rem solid #a0aec0;
          border-radius: 0.3rem;
        }

        .checkmark:after {
          position: absolute;
          content: '';
          display: none;
        }

        .name {
          width: 25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .menu-item:first-child {
      margin-top: 0;
    }
  }

  .loader {
    margin: 1.25rem auto;
    border: 0.4rem solid #f3f3f3;
    border-top: 0.4rem solid #4a5568;
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;
    animation: spin 2s linear infinite;
  }

  .submit-btn {
    width: 28rem;
    height: 3.6rem;
    margin: auto;
    margin-top: 2rem;
    border: 0.1rem solid #cbd5e0;
    border-radius: 0.4rem;
    background: #ffffff;
    box-shadow: 0rem 0.2rem 0.4rem rgba(24, 39, 75, 0.12);
    padding: 0.7rem 1rem;
    font-weight: bold;
    color: #4a5568;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default MenuSelection;
