import styled from 'styled-components';

const PeriodicBarContainer = styled.div`
  background: #edf2f7;
  border-radius: 0.4rem;
  padding: 0.3rem;
  display: flex;
  justify-content: space-between;

  label {
    div {
      width: 8.9rem;
      height: 2rem;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 1.6rem;
      text-align: center;
      vertical-align: middle;
      color: #718096;
      padding: 0.2rem;
    }

    input {
      display: none;
    }

    input[type='radio']:checked + div {
      background: #ffffff;
      font-weight: bold;
      color: #4a5568;
      border-radius: 0.4rem;
    }
  }
`;

export default PeriodicBarContainer;
