import React from 'react';
import { useNavigate } from 'react-router-dom';

import { map, forward, back, alert } from '../../../../assets/icons';
import { withAuth } from '../../../../helper/utils';

import StoreSelection from '../StoreSelection';
import Sources from '../Sources';
import { Modal, SnackBar, CustomButton, CustomSelect } from '../../../components';

import SourceSettingsContainer from './index.style';

const sourceOpts = [
  { value: 'Eats365', label: 'Eats365' },
  { value: 'FoodMarketHubV1', label: 'FoodMarketHub V1' },
  { value: 'FoodMarketHubV1r5', label: 'FoodMarketHub V1.5' },
  { value: 'FoodMarketHubV1r5PH', label: 'FoodMarketHub V1.5 PH' },
  { value: 'QsrQoreApi', label: 'QsrQoreApi' },
  { value: 'QsrQoreApiPH', label: 'QsrQoreApi PH' },
  { value: 'StoreHub', label: 'StoreHub' },
];

function AddSource(props) {
  const { setForm, store, setStore } = props;

  const [sourceAdditionalInfos, setSourceAdditionalInfos] = React.useState({});
  const [sourceSelect, setSourceSelect] = React.useState({});
  const [sourceStore, setSourceStore] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: {} });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  React.useEffect(() => {
    if (store.source) {
      const infos = {};
      const source = sourceOpts.find((value) => store.source.type === value.value);
      for (let i = 0; i < store.source.additionalInfos.length; i += 1) {
        const thisInfo = store.source.additionalInfos[i];
        infos[thisInfo.key] = thisInfo.value;
      }
      setSourceStore({ value: store.source });
      setSourceSelect(source);
      setSourceAdditionalInfos(infos);
    }
  }, []);

  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  const onSourceSelectChange = (source) => {
    if (source) {
      setStore({ ...store, source: { type: source.value } });
      setSourceStore(null);
      setSourceSelect(source);
    }
  };

  const handleChange = (e) => setSourceAdditionalInfos({ ...sourceAdditionalInfos, [e.target.id]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!sourceStore) setShowModal(true);
    else {
      const addInfos = Object.entries(sourceAdditionalInfos).map(([k, v]) => ({ key: k, value: v }));
      const st = {
        ...store,
        source: {
          ...store.source,
          ...sourceStore.value,
          additionalInfos: addInfos.concat(sourceStore.value.additionalInfos),
        },
      };

      setStore(st);
      setForm('addStore');
    }
  };

  let renderSource = <div className="buffer-137px" />;
  if (store.source) {
    switch (store.source.type) {
      case 'Eats365':
        renderSource = (
          <Sources.Eats365
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'FoodMarketHubV1':
        renderSource = (
          <Sources.FoodMarketHubV1
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'FoodMarketHubV1r5':
        renderSource = (
          <Sources.FoodMarketHubV1r5
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'FoodMarketHubV1r5PH':
        renderSource = (
          <Sources.FoodMarketHubV1r5PH
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'QsrQoreApi':
        renderSource = (
          <Sources.QsrQoreApi
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'QsrQoreApiPH':
        renderSource = (
          <Sources.QsrQoreApiPH
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      case 'StoreHub':
        renderSource = (
          <Sources.StoreHub
            handleChange={handleChange}
            sourceAdditionalInfos={sourceAdditionalInfos}
            disableInput={sourceStore}
          />
        );
        break;
      default:
        renderSource = <div className="buffer-137px" />;
        break;
    }
  }

  let renderSelectedSourceStore = '';
  if (sourceStore) {
    renderSelectedSourceStore = (
      <>
        <div className="selected-store">Store selection</div>
        <div className="selected-store-detail" onClick={() => setShowModal(true)}>
          <img src={map} className="map" alt="map" />
          <div className="name">{sourceStore.value.name}</div>
          <img src={forward} className="forward" alt="forward" />
        </div>
      </>
    );
  } else {
    renderSelectedSourceStore = <div className="buffer-76px" />;
  }

  return (
    <SourceSettingsContainer>
      <div className="header">
        <img src={back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">POS System</div>
      </div>
      <form className="source-form" onSubmit={onSubmit}>
        <div className="login">Login credentials</div>
        <CustomSelect
          options={sourceOpts}
          title="POS System"
          onChange={onSourceSelectChange}
          inputId="type"
          value={sourceSelect}
        />
        {renderSource}
        {renderSelectedSourceStore}
        <div className="btn-container">
          <CustomButton type="submit" value="NEXT" theme="default" disabled={!(store.source && store.source.type)} />
        </div>
      </form>
      <Modal onClose={() => setShowModal(false)} open={showModal}>
        <StoreSelection
          onSubmitted={() => setShowModal(false)}
          onClosed={() => setShowModal(false)}
          onError={() => {
            setSnackBarDetails({ text: 'Invalid login credentials.', icon: alert });
            setOpenSnackBar(true);
          }}
          store={store}
          sourceAdditionalInfos={sourceAdditionalInfos}
          sourceStore={sourceStore}
          setSourceStore={setSourceStore}
        />
      </Modal>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </SourceSettingsContainer>
  );
}

export default withAuth(AddSource);
