import styled from 'styled-components';

const DateRangePicker = styled.label`
  font-size: 1.6rem;

  .label {
    color: #4a5568;
  }
  .content {
    & > *:not(*:first-child) {
      margin-top: 1.5rem;
    }
  }

  .date-picker {
    .control {
      display: flex;
      align-items: center;
      width: 100%;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      background: white;

      .calendar {
        margin: auto 0.8rem;
        height: 2rem;
        width: 2rem;
      }

      .date-input {
        padding: 0.7rem 1rem;
        width: 100%;
        height: 3.6rem;
        border: none;
        border-left: 0.1rem solid #a0aec0;
        background: none;
        color: #4a5568;
        text-align: left;
      }
    }
  }
`;

export default DateRangePicker;
