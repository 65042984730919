import styled from 'styled-components';

const UploadModalContainer = styled.div`
  width: 29rem;
  height: 38rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;

  .modal-title {
    width: 24rem;
    height: 2rem;
    font-weight: bold;
    color: #2d3748;
  }

  .modal-img {
    width: 25rem;
    height: 12.4rem;
  }

  .modal-body {
    width: 24rem;
    margin: 2rem 0 3rem 0;
    color: #4a5568;
  }

  .btn-container {
    display: flex;
    margin: 2rem auto 0 auto;
  }

  .back {
    display: inline-block;
    margin-top: 1rem;
    text-align: center;
    color: #1f72c4;
    text-decoration-line: none;
    font-size: 1.6rem;
  }
`;

export default UploadModalContainer;
