import styled from 'styled-components';

const VerifyingInviteContainer = styled.div`
  .title {
    color: #2d3748;
    margin: 2rem auto 0 auto;
    width: 28rem;
    height: 5.2rem;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 5.2rem;
  }

  .card {
    position: relative;
    margin: 5.2rem auto 0 auto;
    padding: 2rem;
    background: #ffffff;
    width: 28rem;
    height: 32.2rem;
    border: 0.1rem dashed #edf2f7;
    border-radius: 1.5rem;
    color: #2d3748;
  }

  .new-store-img {
    position: absolute;
    height: 19.8rem;
    width: 19.8rem;
    top: -3.2rem;
    left: 4.1rem;
  }

  .desc {
    margin-top: 16.6rem;

    .store-name {
      font-weight: bold;
    }
  }

  .btn-container {
    margin-top: 2rem;
    width: 24rem;
  }

  .signout {
    display: inline-block;
    margin-top: max(2.8rem, calc(100vh - 49.6rem));
    margin-left: calc((100% - 26rem) / 2);
    text-align: center;
    width: 26rem;
    color: #bf4119;
    text-decoration-line: none;
  }
`;

export default VerifyingInviteContainer;
