import React from 'react';
import { images } from '../../../../../assets';
import { CustomButton } from '../../../../components';

import UploadModalContainer from './index.style';

function UploadModal(props) {
  const { onSubmitted } = props;
  const onClick = () => {
    onSubmitted();
  };
  return (
    <UploadModalContainer>
      <div className="modal-title">We’re processing your file</div>
      <div className="modal-body">
        This may take a minute or two. We will inform you in your email once the process is complete
      </div>
      <img className="modal-img" src={images.uploadSuccess} alt="upload-success" />
      <div className="btn-container">
        <CustomButton type="button" value="YUP, GOT IT" theme="default" onClick={onClick} />
      </div>
    </UploadModalContainer>
  );
}

export default UploadModal;
