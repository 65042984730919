import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import * as V from 'victory';
import _ from 'lodash';

import { withAuth, UserContext } from '../helper/utils';
import { icons } from '../assets';
import { storeByIdGetService, storeGetService, dataSummary, storeKitchenItem, storeDeleteService } from '../services';

import { Modal, SuccessModal, SnackBar } from './components';
import { ConfirmDeleteModal, Invite } from './Store/components';
import Account from './Account';
import Event from './Event';
import KitchenItems from './KitchenItems';
import Procurement from './Procurement';
import Sales from './Sales';
import Store from './Store';
import { StoreContainer, SideBarContainer } from './index.style';

function App() {
  const userContext = React.useContext(UserContext);
  const { presetStoreId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [userStores, setUserStores] = React.useState([]);
  const [storeFilter, setStoreFilter] = React.useState('');
  const [showSideMenu, setShowSideMenu] = React.useState(false);
  const [showQuickSelect, setShowQuickSelect] = React.useState(false);
  const [saleOrder, setSaleOrder] = React.useState({ sale: null, order: null });
  const [thumbnailChartData, setThumbnailChartData] = React.useState([]);
  const [kitchenItems, setKitchenItems] = React.useState([]);
  const [storeCreatedSuccess, setStoreCreatedSuccess] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const navigate = useNavigate();

  const getStores = (cb) => {
    storeGetService(userContext.auth.token).then((res) => {
      const stores = res.data;
      if (stores.length > 0) setUserStores(stores);
      else setUserStores([]);
      cb(res.data);
    });
  };

  // all the stores
  React.useEffect(() => {
    if (!presetStoreId) {
      getStores((stores) => {
        if (stores.length > 0) navigate(`/${stores[0]._id}`);
        else navigate('/store/new');
      });
    } else {
      getStores((stores) => {
        if (!stores.find((s) => s._id === presetStoreId)) navigate(`/${stores[0]._id}`);
        else {
          if (searchParams.get('successfullyCreated')) {
            setStoreCreatedSuccess(true);
            setSearchParams({});
          }
          storeByIdGetService(userContext.auth.token, presetStoreId).then((res) => {
            userContext.setSelectedStore(res.data);
          });
        }
      });
    }
  }, [presetStoreId]);

  // selected store sale/order
  React.useEffect(() => {
    if (userContext.selectedStore && presetStoreId && userContext.selectedStore._id === presetStoreId) {
      const data = {
        storeId: presetStoreId,
        startDate: moment().startOf('D').toDate(),
        endDate: moment().startOf('D').add(1, 'd').toDate(),
        durationInterval: 'P1D',
        conditions: [
          {
            category: 'Sale',
            dataKeyName: 'sale',
            mergeData: true,
            defaultValue: 0,
          },
          {
            category: 'Sale',
            dataKeyName: 'order',
            mergeData: true,
            defaultValue: 0,
          },
        ],
      };
      dataSummary(userContext.auth.token, data)
        .then((res) => {
          const dt = res.data;
          if (dt.length > 0) setSaleOrder({ sale: dt[0].sale, order: dt[0].order });
          else setSaleOrder({ sale: null, order: null });
        })
        .catch(() => {
          setSaleOrder({ sale: null, order: null });
        });
    }
  }, [userContext.selectedStore, presetStoreId]);

  // Thumbnail chart data
  React.useEffect(() => {
    if (userContext.selectedStore && presetStoreId && userContext.selectedStore._id === presetStoreId) {
      const data = {
        storeId: presetStoreId,
        startDate: moment().startOf('D').toDate(),
        endDate: moment().startOf('D').add(1, 'd').toDate(),
        durationInterval: 'PT1H',
        conditions: [
          {
            category: 'Sale',
            dataKeyName: 'sale',
            mergeData: true,
            defaultValue: 0,
          },
        ],
      };
      dataSummary(userContext.auth.token, data)
        .then((res) => {
          let dt = res.data;
          if (dt && dt.length > 0) {
            dt = dt.map((x) => ({ date: moment(x.date).hour() + 1, sale: x.sale }));
            setThumbnailChartData(dt);
          } else setThumbnailChartData([]);
        })
        .catch(() => {
          setThumbnailChartData([]);
        });
    }
  }, [userContext.selectedStore, presetStoreId]);

  // top 5 kitchen items quick overview
  React.useEffect(() => {
    if (userContext.selectedStore && presetStoreId && userContext.selectedStore._id === presetStoreId) {
      storeKitchenItem(userContext.auth.token, { storeId: presetStoreId })
        .then((res) => res.data)
        .then((kitchItems) => {
          if (kitchItems && kitchItems.length > 0) {
            // top five kitchen items
            const top5KitchenItems = _.sortBy(kitchItems, ['name']).slice(0, 5);

            const data = {
              storeId: presetStoreId,
              startDate: moment().subtract(1, 'h').startOf('h').toDate(),
              endDate: moment().add(1, 'h').startOf('h').toDate(),
              durationInterval: 'PT1H',
              conditions: top5KitchenItems.map((i) => ({
                category: 'KitchenItem',
                dataKeyName: i.name,
                mergeData: true,
                defaultValue: 0,
              })),
            };
            dataSummary(userContext.auth.token, data).then((res) => {
              const dt = res.data;
              if (dt && dt.length > 0) {
                const kitchenItemsSales = top5KitchenItems.map((i) => {
                  const sales = dt[1][i.name] ? dt[1][i.name] : 0;
                  const pastSales = dt[0][i.name] ? dt[0][i.name] : 0;

                  let growth = 0;
                  if (pastSales) growth = Math.round(((sales - pastSales) / pastSales) * 100);
                  else if (sales) growth = 100;
                  return { ...i, sales, growth };
                });
                setKitchenItems(kitchenItemsSales);
              } else setKitchenItems([]);
            });
          } else setKitchenItems([]);
        })
        .catch(() => {
          setKitchenItems([]);
        });
    }
  }, [userContext.selectedStore, presetStoreId]);

  const onMenuClick = () => {
    setShowSideMenu(true);
  };

  const onFilterChange = (e) => {
    setStoreFilter(e.target.value);
  };

  const onSalesReportsClick = () => {
    if (saleOrder.sale || saleOrder.order) navigate('/sales/reports');
  };

  const onImportHistoricalClick = () => {
    navigate('/store/import-historical');
  };

  const onExportDataClick = () => {
    navigate('/store/export-data');
  };

  const onEventClick = () => {
    navigate('/event');
  };

  const onKitchenItemsClick = () => {
    navigate('/kitchen-items');
  };

  const onProcurementClick = () => {
    navigate('/procurement');
  };

  const onStoreSettingsClick = () => {
    navigate('/store/settings');
  };

  const onPosSettingsClick = () => {
    navigate('/store/source-settings');
  };

  const onBackdropClick = () => {
    setShowSideMenu(false);
    setShowQuickSelect(false);
  };

  const onStoreSelectionClick = (storeId) => {
    const selectedStore = userStores.find((s) => s._id === storeId);
    if (selectedStore._id !== userContext.selectedStore._id) {
      const store = storeByIdGetService(userContext.auth.token, selectedStore._id);
      userContext.setSelectedStore(store);
      setShowSideMenu(false);
      navigate(`/${String(selectedStore._id)}`);
    }
  };

  const addStore = () => {
    navigate('/store/add');
  };

  const signout = () => {
    userContext.reset();
    navigate('/account/login', true);
  };

  const onClickStoreItem = () => {
    setShowSideMenu(false);
    setShowQuickSelect(true);
  };

  const onClickClosedInvite = () => {
    setShowInviteModal(false);
  };

  const estimatedDate = moment().endOf('week').add(2, 'd').format('DD/MM/YYYY');
  const waitingTime = moment().endOf('week').add(2, 'd').diff(moment(), 'days');
  const startHr = moment().startOf('hour').format('h');
  const endHr = moment().add(1, 'hour').startOf('hour').format('hA');
  const currentHour = `${startHr}-${endHr}`;

  let stores = null;
  if (userStores.length > 0) {
    stores = userStores
      .filter((s) => s.name.toUpperCase().includes(storeFilter.toUpperCase()))
      .map((s) => {
        let active = false;
        if (userContext.selectedStore) active = s._id === userContext.selectedStore._id;

        return (
          <div className={active ? 'store active' : 'store'} key={s._id} onClick={() => onStoreSelectionClick(s._id)}>
            <div className="name">{s.name}</div>
            <img id={s._id} src={icons.ellipsis} className="ellipsis-icon" alt="ellipsis" onClick={onClickStoreItem} />
          </div>
        );
      });
  }

  const onDelete = () => {
    if (userContext && userContext.selectedStore) {
      storeDeleteService(userContext.auth.token, userContext.selectedStore._id).then(() => {
        setSnackBarDetails({ text: 'Store deleted successfully', icon: icons.checkmark });
        setOpenSnackBar(true);
        setShowQuickSelect(false);
        setShowDeleteModal(false);
        navigate('/');
      });
    }
  };

  return userContext.selectedStore && presetStoreId && userContext.selectedStore._id === presetStoreId ? (
    <>
      <StoreContainer showSideMenu={showQuickSelect || showSideMenu || storeCreatedSuccess}>
        <div className="header">
          <img src={icons.menu} className="menu" alt="menu" onClick={onMenuClick} />
          <div className="store-name">{userContext.selectedStore.name}</div>
        </div>
        <div
          className={
            saleOrder.sale || saleOrder.order || kitchenItems.length > 0 ? 'hidden' : 'not-enough-data-message'
          }
        >
          <img src={icons.info} className="info" alt="info" />
          <div className="message">
            <p>
              Will use your previous sales, menu items, weather to configure. This will take up to a few days to ensure
              our forecast is up to standard.
            </p>
          </div>
        </div>
        <div className="group-title first-stats">Today forecast</div>
        <div className="forecast-subgroup" onClick={onSalesReportsClick}>
          <div className="subgroup-header">
            <img
              src={saleOrder.sale && saleOrder.order ? icons.salesReport : icons.waiting}
              className="title-icon"
              alt="salesReport"
            />
            <div className="title">Sales &amp; Report</div>
          </div>
          <div className={saleOrder.sale && saleOrder.order ? 'forecast-fields' : 'hidden'}>
            <div>Sales</div>
            <div>Orders</div>
          </div>
          <div className="buffer-5px" />
          <div className={saleOrder.sale || saleOrder.order ? 'hidden' : 'no-forecast-msg'}>
            Picking up data to create the sharpest forecast.
          </div>
          <div className={saleOrder.sale || saleOrder.order ? 'hidden' : 'expected-data'}>
            <div>Waiting time :{waitingTime}d</div>
            <div>Expected date :{estimatedDate}</div>
          </div>
          <div className={saleOrder.sale || saleOrder.order ? 'sale-order' : 'hidden'}>
            <div>
              <span>RM </span>
              {saleOrder.sale}
            </div>
            <div>{saleOrder.order}</div>
          </div>
          <div className={saleOrder.sale || saleOrder.order ? 'chart' : 'hidden'}>
            <V.VictoryChart padding={0} height={410} width={580}>
              <V.VictoryAxis
                crossAxis={false}
                dependentAxis
                style={{
                  axis: { stroke: 'none' },
                  grid: { fill: 'none' },
                  tickLabels: { fill: 'none' },
                }}
              />
              <V.VictoryLine
                data={thumbnailChartData}
                x="date"
                y="sale"
                style={{
                  data: { stroke: '#02C2BD', strokeWidth: 10 },
                }}
              />
            </V.VictoryChart>
          </div>
        </div>
        <div className="forecast-subgroup" onClick={onKitchenItemsClick}>
          <div className="subgroup-header">
            <img
              src={kitchenItems.length > 0 ? icons.kitchenMenu : icons.waiting}
              className="title-icon"
              alt="kitchenMenu"
            />
            <div className="title">Kitchen Menu</div>
            <div className="current-time">{currentHour}</div>
          </div>
          {kitchenItems.length > 0 ? (
            <div className="top-5-kitchen-items">
              {kitchenItems.map((k, i) => (
                <div className="kitchen-item" key={`kitchen-items-${i}`}>
                  <div className="name">{k.name}</div>
                  <div className="stats">
                    <div className="units-uom">
                      <div className="units">{k.sales}</div>
                      <div className="uom">{k.unitOfMeasurement}</div>
                    </div>
                    <div className="icon-growth">
                      <img src={icons.upGreen} className="icon" alt="icon" />
                      <div className="growth">{k.growth}%</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className={kitchenItems.length > 0 ? 'buffer-28px' : 'buffer-5px'} />
              <div className="no-forecast-msg">Be right back! Forecast is still brewing.</div>
              <div className="expected-data">
                <div>Waiting time :{waitingTime}d</div>
                <div>Expected date :{estimatedDate}</div>
              </div>
            </>
          )}
        </div>
        <div className="procurement" onClick={onProcurementClick}>
          <img src={icons.kitchenMenu} className="procurement-icon" alt="procurement" />
          <div className="procurement-text">Smart PAR levels</div>
        </div>
        <a href="https://coda.io/form/Forecast-feedback-form_daWtcRdWzPw" target="_blank" rel="noreferrer">
          <img src={icons.feedback} className="feedback" alt="feedback" />
        </a>
        <div className="group-title">Setting</div>
        <div className="settings">
          <div onClick={onStoreSettingsClick}>
            <img src={icons.storeSettings} className="settings-icon" alt="storeSettings" />
            <div className="settings-text">Store settings</div>
            <img src={icons.forward} className="settings-icon" alt="forward" />
          </div>
          <div onClick={onPosSettingsClick}>
            <img src={icons.eats365} className="settings-icon" alt="posSettings" />
            <div className="settings-text">
              {userContext.selectedStore.source ? userContext.selectedStore.source.type : ''}
            </div>
            <img src={icons.forward} className="settings-icon" alt="forward" />
          </div>
        </div>
      </StoreContainer>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
      <Modal onClose={() => setStoreCreatedSuccess(false)} open={storeCreatedSuccess}>
        <SuccessModal onSubmitted={() => setStoreCreatedSuccess(false)} estimatedDate={estimatedDate} />
      </Modal>
      <Modal onClose={() => setShowInviteModal(false)} open={showInviteModal}>
        <Invite onClickClosedInvite={onClickClosedInvite} />
      </Modal>
      <Modal onClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
        <ConfirmDeleteModal onDelete={onDelete} storeName={userContext.selectedStore.name} />
      </Modal>
      <SideBarContainer>
        <div
          className={showSideMenu || showQuickSelect ? 'sidebar-backdrop active' : 'sidebar-backdrop'}
          onClick={onBackdropClick}
        />
        <div className={showSideMenu ? 'sidebar active' : 'sidebar'}>
          <div className="title">Store selection</div>
          <div className="filter-store">
            <img src={icons.search} className="search" alt="search" />
            <input
              type="text"
              placeholder="Search for store"
              className="filter-input"
              value={storeFilter}
              onChange={onFilterChange}
            />
          </div>
          <div className="stores">{stores}</div>
          <div className="actions">
            <div onClick={addStore}>
              <img src={icons.add} className="action-icon" alt="add" />
              <div className="action-text">Add store/outlet</div>
            </div>
            <div>
              <img src={icons.settings} className="action-icon" alt="settings" />
              <div className="action-text">Account setting</div>
            </div>
            <div>
              <img src={icons.support} className="action-icon" alt="support" />
              <div className="action-text">Support</div>
            </div>
            <div onClick={signout}>
              <img src={icons.signOut} className="action-icon" alt="signOut" />
              <div className="action-text sign-out">Sign out</div>
            </div>
          </div>
        </div>
        <div className={showQuickSelect ? 'quick-menu active' : 'quick-menu hide'}>
          <div className="quick-menu-item" onClick={onImportHistoricalClick}>
            <img src={icons.historical} alt="import" />
            <span>Import Historical Sales</span>
          </div>
          <div className="quick-menu-item" onClick={onExportDataClick}>
            <img src={icons.historical} alt="export" />
            <span>Export Store Data</span>
          </div>
          <div className="quick-menu-item" onClick={onEventClick}>
            <img src={icons.calendar} alt="event" />
            <span>Event Management</span>
          </div>
          <div className="quick-menu-item" onClick={() => setShowInviteModal(true)}>
            <img src={icons.add} alt="add" />
            <span>Invite members</span>
          </div>
          <div className="quick-menu-item" onClick={() => setShowDeleteModal(true)}>
            <img src={icons.dustbin} alt="dustbin" />
            <span className="alert">Delete store</span>
          </div>
        </div>
      </SideBarContainer>
    </>
  ) : null;
}

const authApp = withAuth(App);
authApp.Account = Account;
authApp.Event = Event;
authApp.KitchenItems = KitchenItems;
authApp.Procurement = Procurement;
authApp.Sales = Sales;
authApp.Store = Store;

export default authApp;
export { Account, Event, KitchenItems, Sales, Store };
