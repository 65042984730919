import styled from 'styled-components';

const SourceStoreSelectionContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f7fafc;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .close {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .select-source-store-form {
    padding: 2rem;
    display: grid;
  }

  .form-input {
    margin: 1.7rem auto 0 auto;
    width: 28rem;
    color: #4a5568;
  }

  .btn-container {
    margin: auto;
    margin-top: max(31.8rem, calc(100vh - 20.6rem));
  }

  .loader {
    margin: 1.25rem auto;
    border: 0.4rem solid #f3f3f3;
    border-top: 0.4rem solid #4a5568;
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default SourceStoreSelectionContainer;
