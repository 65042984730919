import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CustomButton, CustomSelect, DateRange } from '../../components';
import { back } from '../../../assets/icons';
import { withAuth, UserContext } from '../../../helper/utils';
import { getSummaryKey, dataSummary } from '../../../services';

import ExportDataContainer from './index.style';

const granularityOpts = [
  { value: 'PT30M', label: 'Half-hourly' },
  { value: 'PT1H', label: 'Hourly' },
  { value: 'P1D', label: 'Daily' },
];

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

function ExportData() {
  const userContext = React.useContext(UserContext);
  const [granularity, setGranularity] = React.useState({ value: 'P1D', label: 'Daily' });
  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'd').startOf('d').toDate());
  const [endDate, setEndDate] = React.useState(moment().subtract(1, 'd').endOf('d').toDate());
  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  const onGranularityChange = (g) => {
    if (g) setGranularity(g);
  };

  const onDateChange = ([stDate, enDate]) => {
    setStartDate(stDate);
    setEndDate(enDate);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    getSummaryKey(userContext.auth.token, { storeId: userContext.selectedStore._id })
      .then((res) => {
        const keys = res.data;
        if (keys && keys.length > 0) {
          const conditions = keys
            .filter(
              (k) =>
                (k.category === 'Sale' || k.category === 'MenuItem' || k.category === 'KitchenItem') &&
                k.type === 'history'
            )
            .map((k) => ({
              category: k.category,
              dataKeyName: k.name,
              mergeData: false,
              defaultValue: 0,
              trim: false,
            }));

          const data = {
            storeId: userContext.selectedStore._id,
            startDate,
            endDate,
            durationInterval: granularity.value,
            conditions,
          };
          return data;
        }
        return null;
      })
      .then((data) => dataSummary(userContext.auth.token, data))
      .then((res) => {
        const { data } = res;
        if (data && data.length > 0) {
          const csv = data.reduce(
            (acc, obj) => {
              acc.push(Object.values(obj).join(','));
              return acc;
            },
            [Object.keys(data[0]).join(',')]
          );

          downloadFile({
            data: csv.join('\n'),
            fileName: `export_${userContext.selectedStore._id}.csv`,
            fileType: 'text/csv',
          });
        }
      });
  };

  return (
    <ExportDataContainer>
      <div className="header">
        <img src={back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Export Store Data</div>
      </div>
      <form className="export-data-form" onSubmit={onSubmit}>
        <CustomSelect
          options={granularityOpts}
          title="Granularity"
          onChange={onGranularityChange}
          inputId="granularity"
          value={granularity}
        />
        <DateRange onDateChange={onDateChange} />
        <div className="btn-container">
          <CustomButton type="submit" value="EXPORT" theme="default" />
        </div>
      </form>
    </ExportDataContainer>
  );
}

export default withAuth(ExportData);
