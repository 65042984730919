import styled from 'styled-components';

const PosSettingsContainer = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .source-form {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .login {
    width: 28rem;
    margin: 0 auto 0.4rem auto;
    font-weight: bold;
    color: #2d3748;
  }

  .form-input {
    margin: 1.5rem auto 0 auto;
    width: 28rem;
    color: #4a5568;
  }

  .form-input > .input {
    width: 28rem;
    margin: 0.5rem 0 0 0;
    height: 3.6rem;
    border: 0.1rem solid #a0aec0;
    border-radius: 0.4rem;
    color: #4a5568;
    padding: 0.7rem 1rem;
  }

  .form-input > .select {
    margin: 0.5rem 0 0 0;
  }

  .buffer-152px {
    height: 15.2rem;
  }

  .buffer-91px {
    height: 9.1rem;
  }

  .selected-store {
    width: 28rem;
    height: 2rem;
    margin: 2rem auto 0 auto;
    font-weight: bold;
    color: #2d3748;
  }

  .selected-store-detail {
    display: flex;
    height: 5.1rem;
    margin: 0 auto;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
    color: #4a5568;
  }

  .selected-store-detail > .map {
    height: 2rem;
    width: 2rem;
  }

  .selected-store-detail > .name {
    height: 2rem;
    width: 22rem;
    margin-left: 1rem;
  }

  .selected-store-detail > .forward {
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
  }

  .btn-container {
    width: 28rem;
    margin: auto;
    margin-top: max(12.9rem, calc(100vh - 39.5rem));
  }
`;

export default PosSettingsContainer;
