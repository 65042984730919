import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UserContext } from '../../../helper/utils';
import { deleteEvent, getEventById, updateEvent } from '../../../services';
import { icons } from '../../../assets';
import { CustomButton, SingleLinedDateRange, Modal } from '../../components';
import StyledDetail from './index.style';
import { ConfirmDeleteModal } from './components';

function Detail() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const [dateRangeList, setDateRangeList] = React.useState([{ startDate: null, endDate: null }]);
  const [event, setEvent] = React.useState({});
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  React.useEffect(() => {
    getEventById(userContext.auth.token, eventId).then((res) => {
      const e = res.data;
      setEvent(e);
      const dRangeList = e.dateRange.map((dr) => ({
        startDate: new Date(dr.startDate),
        endDate: new Date(dr.endDate),
      }));
      setDateRangeList([...dRangeList, { startDate: null, endDate: null }]);
    });
  }, []);

  React.useEffect(() => {
    const value = dateRangeList[dateRangeList.length - 1];
    if (value.startDate && value.endDate) {
      setDateRangeList([...dateRangeList, { startDate: null, endDate: null }]);
    }
  }, [dateRangeList]);

  const onBackClick = () => {
    navigate('/event');
  };

  const onRemarkChanged = (e) => {
    setEvent({ ...event, remark: e.target.value });
  };

  const onDateChange = ([stDt, enDt], index) => {
    const temp = [...dateRangeList];
    temp[index] = { startDate: stDt, endDate: enDt };
    setDateRangeList(temp);
  };

  const onClickCrossButton = (e, index) => {
    const temp = [...dateRangeList];
    temp.splice(index, 1);
    setDateRangeList(temp);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const temp = { ...event, dateRange: dateRangeList.filter((dr) => dr.startDate && dr.endDate) };
    if (temp._id) delete temp._id;
    updateEvent(userContext.auth.token, eventId, temp).then((res) => {
      navigate('/event?successfullyUpdated=true');
    });
  };

  const getDateRangeList = () =>
    dateRangeList.map((value, index, arr) => (
      <div className="date-range" key={`input_${index}`}>
        <SingleLinedDateRange
          onDateChange={(e) => onDateChange(e, index)}
          startDate={value.startDate}
          endDate={value.endDate}
        />
        {arr.length > 1 && index !== arr.length - 1 ? (
          <img
            className="clear-date-range"
            src={icons.closeRed}
            alt="clear-date-range"
            onClick={(e) => onClickCrossButton(e, index)}
          />
        ) : (
          ''
        )}
      </div>
    ));

  const onDelete = () => {
    deleteEvent(userContext.auth.token, event._id).then(() => {
      navigate('/event');
    });
  };

  return (
    <StyledDetail>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Add Event</div>
        <img src={icons.dustbin} className="delete" alt="delete" onClick={() => setShowDeleteModal(true)} />
      </div>
      <div className="content">
        <form className="event-form" onSubmit={onSubmit}>
          <div className="inputs">
            <label className="name" htmlFor="name">
              <div className="label">Event Name</div>
              <input className="input" type="text" id="name" defaultValue={event.name ? event.name : ''} disabled />
            </label>
            <label className="remark-input" htmlFor="remark">
              <div className="label">Remark (optional)</div>
              <input
                className="input"
                type="text"
                id="remark"
                value={event.remark ? event.remark : ''}
                onChange={onRemarkChanged}
              />
            </label>
            <label className="date-range-input" htmlFor="date-range">
              <div className="label">Date range</div>
              <div className="date-range-list">{getDateRangeList()}</div>
            </label>
          </div>
          <div className="save-btn">
            <CustomButton type="submit" value="Save" theme="default" disabled={false} />
          </div>
        </form>
      </div>
      <Modal onClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
        <ConfirmDeleteModal onDelete={onDelete} eventName={event.name} />
      </Modal>
    </StyledDetail>
  );
}

export default Detail;
