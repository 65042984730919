import React from 'react';
import { Navigate } from 'react-router-dom';
import App from '../App';

const routes = [
  { path: 'account/forget-password', element: <App.Account.ForgetPassword /> },
  { path: 'account/new-password', element: <App.Account.NewPassword /> },
  { path: 'account/login', element: <App.Account.Login /> },
  { path: 'account/signup', element: <App.Account.SignUp /> },
  { path: 'account/verify', element: <App.Account.Verify /> },
  { path: 'event/add', element: <App.Event.Add /> },
  { path: 'event/:eventId/detail', element: <App.Event.Detail /> },
  { path: 'event/', element: <App.Event /> },
  { path: 'store/new', element: <App.Store.New /> },
  { path: 'store/add', element: <App.Store.Add /> },
  { path: 'store/:storeId/invites', element: <App.Store.Invites /> },
  { path: 'store/settings', element: <App.Store.Settings /> },
  { path: 'store/export-data', element: <App.Store.ExportData /> },
  { path: 'store/import-historical', element: <App.Store.ImportHistorical /> },
  { path: 'store/source-settings', element: <App.Store.SourceSettings /> },
  { path: 'sales/reports', element: <App.Sales.Reports /> },
  { path: 'kitchen-items/:name/detail', element: <App.KitchenItems.Detail /> },
  { path: 'kitchen-items/add', element: <App.KitchenItems.Add /> },
  { path: 'kitchen-items/', element: <App.KitchenItems /> },
  { path: 'procurement/', element: <App.Procurement /> },
  { path: 'procurement/import', element: <App.Procurement.Import /> },
  {
    path: '/',
    children: [
      { path: ':presetStoreId', element: <App /> },
      { path: '/', element: <App /> },
    ],
  },
  { path: '/*', element: <Navigate to="/" /> },
];

export default routes;
