import styled from 'styled-components';

const ForgetPasswordContainer = styled.div`
  height: 100%;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .forget-password-form {
    padding: 2rem 3rem;

    .form-input {
      margin: 1.5rem auto 0 auto;
      color: #4a5568;
    }

    .form-input > .input {
      width: 26rem;
      margin-top: 0.5rem;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      color: #4a5568;
      padding: 0.7rem 1rem;
    }

    .btn {
      margin-top: max(33.5rem, calc(100vh - 18.9rem));
    }
  }
`;

export default ForgetPasswordContainer;
