import styled from 'styled-components';

const LearnContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f7fafc;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .close {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .instruction-list {
    margin: 2rem;
    display: flex;
    flex-direction: column;

    .instruction {
      display: flex;
      flex-direction: row;
      margin: 0 0 3rem 0;

      .circle {
        display: inline-flex;
        position: relative;
        margin: 0 1.5rem 0 0;
        border-radius: 50%;
        width: 3.2rem;
        height: 3.2rem;
        justify-content: center;
        align-items: center;
        color: #ffc35d;
        background-color: #fdefd7;
        z-index: 2;

        &:before {
          display: inline-block;
          position: absolute;
          border: 1px solid #ffc35d;
          width: 0;
          //   height: 3.5rem;
          content: '';
          left: 50%;
          z-index: 1;
          top: 3.1rem;
          margin-left: -1px;
        }
      }

      .instruction-text {
        font-size: 1.2rem;
        line-height: 1.6rem;

        .title {
          margin: 0 0 0.5rem 0;
          font-weight: bold;
          color: #4a5568;
        }
        .text {
          color: #718096;
        }
      }

      &:first-child {
        .circle {
          &:before {
            height: 3.5rem;
          }
        }
      }

      &:nth-child(2) {
        .circle {
          &:before {
            height: 5.1rem;
          }
        }
      }
    }
  }
`;

export default LearnContainer;
