import React from 'react';
import { useNavigate } from 'react-router-dom';

import { face, back, locate, forward } from '../../../assets/icons';
import { UserContext, withAuth } from '../../../helper/utils';
import { storeDeleteService, storePatchService, storeGetMember } from '../../../services';
import { Modal, CustomButton } from '../../components';
import { ConfirmDeleteModal, Location, Invite } from '../components';

import StoreSettingsContainer from './index.style';

function Settings() {
  const navigate = useNavigate();
  const [store, setStore] = React.useState({ name: '', description: '' });
  const [location, setLocation] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [memberList, setMemberList] = React.useState([]);
  const [showInviteModal, setShowInviteModal] = React.useState(false);

  const userContext = React.useContext(UserContext);

  const onBackClick = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    storeGetMember(userContext.auth.token, userContext.selectedStore._id).then((res) => {
      const storeMembers = { ...res.data.storeMembers };
      setMemberList(storeMembers.members);
    });
  }, []);

  React.useEffect(() => {
    if (userContext && userContext.selectedStore) {
      setStore(userContext.selectedStore);
      setLocation(userContext.selectedStore.location);
    }
  }, []);

  React.useEffect(() => {
    if (userContext.selectedStore)
      setStore({ name: userContext.selectedStore.name, description: userContext.selectedStore.description });
  }, [userContext.selectedStore]);

  const handleChange = (e) => {
    setStore({ ...store, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (userContext && userContext.selectedStore) {
      storePatchService(userContext.auth.token, userContext.selectedStore._id, { ...store, location }).then(() => {
        navigate('/');
      });
    }
  };

  const onDelete = () => {
    if (userContext && userContext.selectedStore) {
      storeDeleteService(userContext.auth.token, userContext.selectedStore._id).then(() => {
        navigate('/');
      });
    }
  };

  const getMemberList = () => {
    let list = '';

    if (memberList) {
      list = memberList.map((member) => {
        const checkCurrentUser = member.userId === userContext.auth.userId;
        return (
          <div key={member.userId}>
            <div className="member-container">
              <img className={checkCurrentUser ? '' : 'hide'} src={face} alt="this-is-you" />
              <div className="member-details">
                <div>{`${member.name} ${checkCurrentUser ? '(You)' : ''}`}</div>
                <div>{member.email}</div>
              </div>
            </div>
            <hr />
          </div>
        );
      });
    }

    return list;
  };

  const onClickClosedInvite = () => {
    setShowInviteModal(false);
  };

  return (
    <StoreSettingsContainer openModal={showInviteModal}>
      <div className="header">
        <img src={back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Store settings</div>
      </div>
      <form className="store-settings-form" onSubmit={onSubmit}>
        <div className="sub-title">Store</div>
        <label className="form-input" htmlFor="name">
          <div className="label">Store/outlet name</div>
          <input className="input" type="text" id="name" onChange={handleChange} value={store.name} required />
        </label>
        <label className="form-input" htmlFor="description">
          <div className="label">Description (optional)</div>
          <textarea className="input multi" id="description" onChange={handleChange} value={store.description} />
        </label>
        <div className="location-header">Location</div>
        <div className="location-detail" onClick={() => setShowModal(true)}>
          <img src={locate} className="locate" alt="locate" />
          <div className="name"> {location ? location.address.main : 'Use my current location'}</div>
          <img src={forward} className="forward" alt="forward" />
        </div>
        <div className="location-desc">
          This allow us to gather weather, traffic, holiday data around your location to ensure more accurate forecast
        </div>
        <div className="members-header">Members</div>
        <div className="member-list-container" onClick={() => setShowInviteModal(true)}>
          {getMemberList()}
        </div>
        <div className="bottom-btn-container">
          <div className="btn-container">
            <CustomButton type="submit" value="SAVE" theme="default" />
          </div>
          <div className="delete-btn" onClick={() => setShowDeleteModal(true)}>
            Delete store
          </div>
        </div>
      </form>
      <Modal onClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
        <ConfirmDeleteModal onDelete={onDelete} storeName={store.name} />
      </Modal>
      <Modal onClose={() => setShowModal(false)} open={showModal}>
        <Location onSubmitted={() => setShowModal(false)} location={location} setLocation={setLocation} />
      </Modal>
      <Modal onClose={() => setShowInviteModal(false)} open={showInviteModal}>
        <Invite onClickClosedInvite={onClickClosedInvite} />
      </Modal>
    </StoreSettingsContainer>
  );
}

export default withAuth(Settings);
