import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { emailVerified } from '../../../assets/images';
import { CustomButton, SnackBar } from '../../components';
import { verifyEmail, resendEmailVerification } from '../../../services';
import { icons } from '../../../assets';

import VerifyEmailContainer from './index.style';

function Verify() {
  const [searchParams] = useSearchParams();
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isResend, setIsResend] = React.useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    const verifyEmailUrl = `${window.location.origin}/account/verify?token=`;
    resendEmailVerification({ verifyEmailUrl, email })
      .then(() => {
        setSnackBarDetails({ text: 'A new verification email has been sent!', icon: icons.checkmark });
        setOpenSnackBar(true);
      })
      .then(() => {
        navigate('/account/login');
      });
  };

  React.useEffect(() => {
    const verifyEmailToken = searchParams.get('token');
    if (!verifyEmailToken) navigate('/account/login');

    verifyEmail({ verifyEmailToken })
      .then(() => {
        setSnackBarDetails({
          text: 'Your email has been verified! You will be directed to login page soon',
          icon: icons.checkmark,
        });
        setOpenSnackBar(true);
      })
      .then(() => {
        setTimeout(() => {
          navigate('/account/login');
        }, 4000);
      })
      .catch(() => {
        setSnackBarDetails({
          text: 'Failed to verify the email',
          icon: icons.alert,
        });
        setOpenSnackBar(true);
        setIsResend(true);
      });
  }, []);

  return (
    <VerifyEmailContainer>
      <div className="title">FORECAS+</div>
      <div className="card">
        <img className="verify-email-img" src={emailVerified} alt="new-store" />
        <div className="desc">Resend verification email</div>
        <form className="resend-email-form" onSubmit={submit}>
          <input
            className="email"
            placeholder="name@email.com"
            type="email"
            id="email"
            autoComplete="email"
            onChange={handleChange}
            required
          />
          <div className="btn-container">
            <CustomButton type="submit" value="RESEND EMAIL" theme="action" />
          </div>
        </form>
      </div>
      <div className={isResend ? 'backdrop' : 'backdrop active'} />
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </VerifyEmailContainer>
  );
}

export default Verify;
