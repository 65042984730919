import styled from 'styled-components';

const Snackbar = styled.div`
  z-index: 1;
  visibility: hidden;
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 28rem;
  bottom: 0;
  margin: 2rem;
  padding: 1rem;
  background-color: rgba(33, 43, 54, 0.9);
  opacity: 0.87;
  box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.3);
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: 0.4em;
  align-items: flex-start;

  & > div {
    margin-left: 0.5rem;
    color: #fff;
  }

  .snackbar-icon {
    height: 2rem;
    width: 2rem;
  }

  ${(props) =>
    props.open
      ? `
        visibility: visible;
        animation: fadein 0.5s, fadeout 1s 3s;
      `
      : ''};

  @keyframes fadein {
    to {
      opacity: 0.87;
    }
  }

  @keyframes fadeout {
    to {
      opacity: 0;
    }
  }
`;

export default Snackbar;
