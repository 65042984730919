import styled from 'styled-components';

const Button = styled.input`
  width: 100%;
  height: 3.6rem;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(24, 39, 75, 0.12);
  padding: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;

  ${(props) => {
    switch (props.theme) {
      case 'warning':
        return `
          border: none;
          color: #ffffff;
          background: #ec7d59;`;
      case 'action':
        return `
          border: none;
          color: #403425;
          background: #ffc35d;

          &[disabled] {
            box-shadow: none;
            color: #d5c9b9;
            background: #fdefd7;
          }`;
      default:
        return `
          border: 0.1rem solid #cbd5e0;
          color: #4a5568;
          background: #ffffff;

          &[disabled] {
            box-shadow: none;
            color: #cbd5e0;
          }`;
    }
  }}
`;

export default Button;
