import styled from 'styled-components';

const Select = styled.label`
  .select-title {
    color: #4a5568;
  }
  .select {
    margin-top: 0.5rem;
  }
`;

const sourceSelectStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    minHeight: '3.6rem',
    height: '3.6rem',
    fontSize: '1.6rem',
    border: '0.1rem solid #a0aec0',
    borderRadius: '0.4rem',
    color: '#4a5568',
    background: '#ffffff',
  }),
  indicatorSeparator: () => {},
  singleValue: (provided) => ({
    ...provided,
    color: '#4a5568',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.2rem 0.8rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 'auto 0.2rem',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0.2rem',
    padding: '0.2rem auto',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    '& svg': {
      height: '2rem',
      width: '2rem',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '& > div': {
      padding: '0.8rem',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0.4rem',
    margin: '0.8rem auto',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '30rem',
    padding: '0.4rem 0',
  }),
  option: (provided) => ({
    ...provided,
    padding: '0.8rem 1.2rem',
  }),
};

Select.sourceSelectStyles = sourceSelectStyles;
export default Select;
export { sourceSelectStyles };
