import React from 'react';
import { images } from '../../../assets';
import Button from '../Button';

import SuccessModalContainer from './index.style';

function SuccessModal(props) {
  const { onSubmitted, estimatedDate } = props;
  const onClick = () => {
    onSubmitted();
  };
  return (
    <SuccessModalContainer>
      <div className="modal-title">Completed! Well done.</div>
      <img className="modal-img" src={images.newStoreSettingUp} alt="new-store-setting-up" />
      <div className="modal-body">
        This will take up to a few days to ensure our forecast is up to standard. Estimated to be completed by&nbsp;
        <span className="highlight">{estimatedDate}</span>
      </div>
      <div className="btn-container">
        <Button type="button" value="GOT IT" theme="default" onClick={onClick} />
      </div>
    </SuccessModalContainer>
  );
}

export default SuccessModal;
