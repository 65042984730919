import styled from 'styled-components';

const SuccessModalContainer = styled.div`
  width: 28rem;
  height: 38.6rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;

  .modal-title {
    width: 24rem;
    height: 2rem;
    font-weight: bold;
    color: #2d3748;
  }

  .modal-img {
    width: 15rem;
    height: 15rem;
    margin: 2rem auto 0 auto;
  }

  .modal-body {
    width: 24rem;
    height: 8rem;
    margin-top: 2rem;
    color: #4a5568;

    .highlight {
      font-weight: bold;
    }
  }

  .btn-container {
    width: 24rem;
    margin-top: 2rem;
  }
`;

export default SuccessModalContainer;
