import styled from 'styled-components';

const ExportDataContainer = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .export-data-form {
    padding: 2rem;
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: 1.5rem;
    }

    & > *:first-child {
      margin-top: 0;
    }

    & > .btn-container {
      margin-top: max(20.8rem, calc(100vh - 31.6rem));
    }
  }
`;

export default ExportDataContainer;
