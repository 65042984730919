import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { images } from '../../../../../assets';
import { CustomButton } from '../../../../components';
import { UserContext } from '../../../../../helper/utils';

import UploadModalContainer from './index.style';

function UploadModal() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const onClick = () => {
    navigate('/procurement');
  };
  return (
    <UploadModalContainer>
      <div className="modal-title">Import successfully</div>
      <div className="modal-body">Latest inventory usage has been updated. Let&apos;s get your dynamic PAR levels</div>
      <img className="modal-img" src={images.uploadSuccess} alt="upload-success" />
      <div className="btn-container">
        <CustomButton type="button" value="GET PAR LEVELS" theme="action" onClick={onClick} />
      </div>
      <Link className="back" to={`/${userContext.selectedStore._id}`}>
        Home
      </Link>
    </UploadModalContainer>
  );
}

export default UploadModal;
