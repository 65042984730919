import styled from 'styled-components';

const Detail = styled.div`
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .content {
    padding: 2rem;

    .today-forecast {
      color: #4a5568;
    }

    .total-sales {
      margin-top: 0.5rem;
      display: flex;
      .sales {
        font-weight: bold;
        color: #2d3748;
      }

      .units {
        color: #a0aec0;
      }
    }

    .graph {
      margin-top: 1.5rem;
      height: 21rem;
      width: 28rem;
    }

    .menu-items {
      .menu-item {
        margin-top: 1.5rem;
        height: 3.6rem;
        border-bottom: 0.1rem solid #edf2f7;
      }

      .menu-item:first-child {
        margin-top: 2rem;
      }
    }
  }
`;

export default Detail;
