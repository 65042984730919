import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext, withAuth } from '../../../helper/utils';
import { verifyInvite } from '../../../services';
import { newStoreSettingUp } from '../../../assets/images';
import { CustomButton } from '../../components';
import VerifyingInviteContainer from './index.style';

function Invites() {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const userContext = React.useContext(UserContext);

  const [store, setStore] = React.useState({});

  React.useEffect(() => {
    verifyInvite(userContext.auth.token, {
      userId: userContext.auth.userId,
      storeId,
    }).then((e) => {
      setStore(e.data);
    });
  }, []);

  const onClick = () => {
    navigate(`/${store.id}`);
  };

  return userContext.auth.authenticated ? (
    <VerifyingInviteContainer>
      <div className="title">FORECAS+</div>
      <div className="card">
        <img className="new-store-img" src={newStoreSettingUp} alt="new-store" />
        <div className="desc">
          Congrats! Your invite has been verified and you are now part of{' '}
          <span className="store-name"> {store.name}</span> store/outlet
        </div>
        <div className="btn-container">
          <CustomButton type="submit" value="GO TO STORE/OUTLET" theme="action" onClick={onClick} />
        </div>
      </div>
    </VerifyingInviteContainer>
  ) : (
    ''
  );
}

export default withAuth(Invites);
