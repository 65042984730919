import styled from 'styled-components';

const IncrementableNumBox = styled.div`
  display: flex;
  width: inherit;
  min-width: 9rem;
  height: 3.6rem;
  background: #ffffff;
  border: 0.1rem solid #a0aec0;
  border-radius: 0.4rem;

  .input {
    width: calc(100% - 2.1rem);
    padding: 0.8rem 1rem;
    border-radius: 0.4rem;
    border: none;
    color: #a0aec0;
    text-align: center;
  }

  .control {
    width: 2.2rem;
    height: 3.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-left: 0.1rem solid #a0aec0;

    .button {
      height: 1.6rem;
      width: 2.1rem;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 0.8rem;
        height: 0.5rem;
      }
    }

    .divider {
      height: 0.1rem;
      width: 2.1rem;
      border-bottom: 0.1rem solid #a0aec0;
    }
  }
`;

export default IncrementableNumBox;
