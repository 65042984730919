import React from 'react';

function Eats365(props) {
  const { handleChange, sourceAdditionalInfos, disableInput } = props;

  return (
    <>
      <label className="form-input" htmlFor="client_id">
        <div className="label">Client Id</div>
        <input
          className="input"
          type="text"
          id="client_id"
          value={sourceAdditionalInfos.client_id ? sourceAdditionalInfos.client_id : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
      <label className="form-input" htmlFor="client_secret">
        <div className="label">Client Secret</div>
        <input
          className="input"
          type="text"
          id="client_secret"
          value={sourceAdditionalInfos.client_secret ? sourceAdditionalInfos.client_secret : ''}
          onChange={handleChange}
          required
          disabled={disableInput}
        />
      </label>
    </>
  );
}

export default Eats365;
