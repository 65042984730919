import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import DurationBarContainer from './index.style';

import { icons } from '../../../../../assets';

function DurationBar(props) {
  const { setRangeParam, period, periodParam, rangeParam } = props;

  const [dateDisplay, setDateDisplay] = React.useState(null);
  const [navigationButton, setNavigationButton] = React.useState({ left: true, right: true });

  React.useEffect(() => {
    let displayDate;

    if (period === 'weekly') {
      const startDate = moment(rangeParam[period].startDate).format('DD MMM');
      const endDate = moment(rangeParam[period].endDate).format('DD MMM YYYY');
      displayDate = `${startDate} - ${endDate}`;
    } else {
      displayDate = moment(rangeParam[period].startDate).format(periodParam[period].format);
    }

    setDateDisplay(displayDate);
  }, [period]);

  React.useEffect(() => {
    const day = period === 'weekly' ? 11 : 7;
    const display = period === 'weekly' ? dateDisplay.split(' - ')[1] : dateDisplay;
    const dateLimit = moment().add(day, 'd');
    const currentDate = moment(display).add(periodParam[period].duration, periodParam[period].shortForm);
    const diff = dateLimit.diff(currentDate);

    if (diff < 0) {
      setNavigationButton({ ...navigationButton, right: false });
    } else {
      setNavigationButton({ ...navigationButton, right: true });
    }
  }, [dateDisplay]);

  const handleChange = (value) => {
    let date;

    switch (period) {
      case 'daily':
        const daily = {};
        if (navigationButton.right && value === 'left') {
          daily.startDate = moment(rangeParam[period].startDate)
            .startOf(periodParam[period].shortForm)
            .subtract(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();
          daily.endDate = moment(rangeParam[period].startDate)
            .endOf(periodParam[period].shortForm)
            .subtract(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();

          date = moment(daily.startDate).format(periodParam[period].format);
        }

        if (navigationButton.right && value === 'right') {
          daily.startDate = moment(rangeParam[period].startDate)
            .startOf(periodParam[period].shortForm)
            .add(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();
          daily.endDate = moment(rangeParam[period].startDate)
            .endOf(periodParam[period].shortForm)
            .add(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();

          date = moment(daily.startDate).format(periodParam[period].format);
        }

        if (!_.isEmpty(daily)) setRangeParam({ ...rangeParam, daily });

        break;
      case 'weekly':
        const weekly = {};

        if (navigationButton.left && value === 'left') {
          weekly.startDate = moment(rangeParam[period].startDate).startOf('d').subtract(7, 'days').toDate();
          weekly.endDate = moment(rangeParam[period].startDate).subtract(1, 'days').endOf('d').toDate();

          date = `${moment(weekly.startDate).format('DD MMM')} - ${moment(weekly.endDate).format('DD MMM YYYY')}`;
        }
        if (navigationButton.right && value === 'right') {
          weekly.startDate = moment(rangeParam[period].endDate).startOf('d').add(1, 'days').toDate();
          weekly.endDate = moment(rangeParam[period].endDate).endOf('d').add(7, 'days').toDate();

          date = `${moment(weekly.startDate).format('DD MMM')} - ${moment(weekly.endDate).format('DD MMM YYYY')}`;
        }

        if (!_.isEmpty(weekly)) setRangeParam({ ...rangeParam, weekly });

        break;

      default:
        const monthly = {};
        if (navigationButton.left && value === 'left') {
          monthly.startDate = moment(rangeParam[period].startDate)
            .startOf(periodParam[period].shortForm)
            .subtract(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();
          monthly.endDate = moment(rangeParam[period].startDate)
            .endOf(periodParam[period].shortForm)
            .subtract(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();

          date = moment(monthly.startDate).format(periodParam[period].format);
        }

        if (navigationButton.right && value === 'right') {
          monthly.startDate = moment(rangeParam[period].startDate)
            .startOf(periodParam[period].shortForm)
            .add(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();
          monthly.endDate = moment(rangeParam[period].startDate)
            .endOf(periodParam[period].shortForm)
            .add(periodParam[period].duration, periodParam[period].shortForm)
            .toDate();

          date = moment(monthly.startDate).format(periodParam[period].format);
        }

        if (!_.isEmpty(monthly)) setRangeParam({ ...rangeParam, monthly });
    }

    if (navigationButton[value]) {
      setDateDisplay(date);
    }
  };

  return (
    <DurationBarContainer>
      <div
        className={`arrow-backward-container ${navigationButton.left ? 'active' : ''}`}
        onClick={() => handleChange('left')}
      >
        <img src={navigationButton.left ? icons.forward : icons.forwardInactive} alt="arrow-backward" />
      </div>
      <div className="duration">{dateDisplay}</div>
      <div
        className={`arrow-forward-container ${navigationButton.right ? 'active' : ''}`}
        onClick={() => handleChange('right')}
      >
        <img src={navigationButton.right ? icons.forward : icons.forwardInactive} alt="arrow-forward" />
      </div>
    </DurationBarContainer>
  );
}

export default DurationBar;
