import Eats365 from './Eats365';
import FoodMarketHubV1 from './FoodMarketHubV1';
import FoodMarketHubV1r5 from './FoodMarketHubV1r5';
import FoodMarketHubV1r5PH from './FoodMarketHubV1r5PH';
import QsrQoreApi from './QsrQoreApi';
import QsrQoreApiPH from './QsrQoreApiPH';
import StoreHub from './StoreHub';

export default {
  Eats365,
  FoodMarketHubV1,
  FoodMarketHubV1r5,
  FoodMarketHubV1r5PH,
  QsrQoreApi,
  QsrQoreApiPH,
  StoreHub,
};
export { Eats365, FoodMarketHubV1, FoodMarketHubV1r5, FoodMarketHubV1r5PH, QsrQoreApi, QsrQoreApiPH, StoreHub };
