import add from './add.svg';
import back from './back.svg';
import calendar from './calendar.svg';
import close from './close.svg';
import closeRed from './closeRed.svg';
import down from './down.svg';
import dustbin from './dustbin.svg';
import eats365 from './eats365.svg';
import ellipsis from './ellipsis.svg';
import face from './face.svg';
import forward from './forward.svg';
import forwardInactive from './forwardInactive.svg';
import feedback from './feedback.svg';
import info from './info.svg';
import kitchenItem from './kitchenItem.svg';
import kitchenMenu from './kitchenMenu.svg';
import locate from './locate.svg';
import map from './map.svg';
import menu from './menu.svg';
import myLocation from './myLocation.svg';
import pin from './pin.svg';
import plus from './plus.svg';
import questionMark from './questionMark.svg';
import salesReport from './salesReport.svg';
import search from './search.svg';
import settings from './settings.svg';
import signOut from './signOut.svg';
import storeSettings from './storeSettings.svg';
import support from './support.svg';
import up from './up.svg';
import upGreen from './upGreen.svg';
import alert from './alert.svg';
import checkmark from './checkmark.svg';
import waiting from './waiting.svg';
import download from './download.svg';
import csv from './csv.svg';
import historical from './historical.svg';

export default {
  add,
  back,
  calendar,
  close,
  closeRed,
  down,
  dustbin,
  eats365,
  ellipsis,
  face,
  feedback,
  forward,
  forwardInactive,
  info,
  kitchenItem,
  kitchenMenu,
  locate,
  map,
  menu,
  myLocation,
  pin,
  plus,
  questionMark,
  salesReport,
  search,
  settings,
  signOut,
  storeSettings,
  support,
  up,
  upGreen,
  alert,
  checkmark,
  waiting,
  download,
  csv,
  historical,
};

export {
  add,
  back,
  calendar,
  close,
  closeRed,
  down,
  dustbin,
  eats365,
  ellipsis,
  face,
  feedback,
  forward,
  forwardInactive,
  info,
  kitchenItem,
  kitchenMenu,
  locate,
  map,
  menu,
  myLocation,
  pin,
  plus,
  questionMark,
  salesReport,
  search,
  settings,
  signOut,
  storeSettings,
  support,
  up,
  upGreen,
  alert,
  checkmark,
  waiting,
  download,
  csv,
  historical,
};
