import ForgetPassword from './ForgetPassword';
import Login from './Login';
import SignUp from './SignUp';
import NewPassword from './NewPassword';
import Verify from './Verify';

export default {
  NewPassword,
  ForgetPassword,
  Login,
  SignUp,
  Verify,
};
export { NewPassword, ForgetPassword, Login, SignUp, Verify };
