import React from 'react';
import moment from 'moment';
import * as V from 'victory';
import { useNavigate } from 'react-router-dom';

import { UserContext, withAuth } from '../../../helper/utils';
import { dataSummary } from '../../../services';
import { Modal } from '../../components';
import { PeriodicBar, NotAccurateModal, DurationBar } from './components';
import { back } from '../../../assets/icons';
import SalesReportsContainer from './index.style';

const periodParam = {
  daily: {
    chartInterval: 'PT1H',
    sumInterval: 'P1D',
    shortForm: 'd',
    index: 8,
    duration: 1,
    format: 'DD MMM YYYY',
  },
  weekly: {
    chartInterval: 'P1D',
    sumInterval: 'P1D',
    shortForm: 'w',
    index: 2,
    duration: 1,
  },
  monthly: {
    chartInterval: 'P1D',
    sumInterval: 'P1D',
    shortForm: 'M',
    momentShortForm: 'month',
    index: 1,
    duration: 1,
    format: 'MMM YYYY',
  },
};

const chartSettings = {
  daily: {
    chartDomain: [0, 23],
    barWidth: 7,
    tickValues: [0, 6, 12, 18],
    tickCount: 4,
    domainPadding: 8.5,
  },
  weekly: {
    chartDomain: [0, 6],
    barWidth: 25,
    tickValues: [0, 1, 2, 3, 4, 5, 6],
    tickCount: 7,
    domainPadding: 17.5,
  },
  monthly: {
    chartDomain: [1, 31],
    barWidth: 5,
    tickValues: [7, 14, 21, 28],
    tickCount: 4,
    domainPadding: 7.5,
  },
};

function SalesReports() {
  const navigate = useNavigate();

  const userContext = React.useContext(UserContext);
  const [period, setPeriod] = React.useState('daily');
  const [chartData, setChartData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [rangeParam, setRangeParam] = React.useState({
    daily: {
      startDate: moment().subtract(1, 'd').startOf('d').toDate(),
      endDate: moment().subtract(1, 'd').endOf('d').toDate(),
    },
    weekly: {
      startDate: moment().startOf('d').subtract(3, 'd').toDate(),
      endDate: moment().add(3, 'd').endOf('d').toDate(),
    },
    monthly: {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    },
  });

  // Get chart data
  React.useEffect(() => {
    let mounted = true;
    if (userContext.selectedStore) {
      const data = {
        storeId: userContext.selectedStore._id,
        startDate: rangeParam[period].startDate,
        endDate: rangeParam[period].endDate,
        durationInterval: periodParam[period].chartInterval,
        conditions: [
          {
            category: 'Sale',
            dataKeyName: 'sale',
            mergeData: false,
            defaultValue: 0,
          },
        ],
      };
      dataSummary(userContext.auth.token, data).then((res) => {
        if (mounted) {
          const dt = res.data;
          if (dt && dt.length > 0) {
            setChartData(dt);
          }
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [userContext.selectedStore, period, rangeParam]);

  // get summary data
  React.useEffect(() => {
    let mounted = true;
    if (userContext.selectedStore) {
      const data = {
        storeId: userContext.selectedStore._id,
        startDate: rangeParam[period].startDate,
        endDate: rangeParam[period].endDate,
        durationInterval: periodParam[period].sumInterval,
        conditions: [
          {
            category: 'Sale',
            dataKeyName: 'sale',
            mergeData: false,
            defaultValue: 0,
          },
          {
            category: 'Sale',
            dataKeyName: 'order',
            mergeData: false,
            defaultValue: 0,
          },
        ],
      };

      dataSummary(userContext.auth.token, data).then((res) => {
        const dt = res.data;

        if (dt && dt.length > 0) {
          const sumData = dt.reduce(
            (acc, value) => {
              if (value['sale-history']) {
                acc['sale-history'] += value['sale-history'];
              }
              if (value['sale-projection']) {
                acc['sale-projection'] += value['sale-projection'];
              }

              if (value['order-history']) {
                acc['order-history'] += value['order-history'];
              }
              if (value['order-projection']) {
                acc['order-projection'] += value['order-projection'];
              }

              return acc;
            },
            {
              'sale-history': 0,
              'sale-projection': 0,
              'order-history': 0,
              'order-projection': 0,
            }
          );

          setSummaryData(sumData);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [userContext.selectedStore, period]);

  const onBackClick = () => {
    navigate(-1);
  };

  const getXValue = (d) => {
    let xValue;

    if (d.date && period === 'daily') {
      const time = moment(d.date).format('HH');
      xValue = parseInt(time, 10);
    }

    if (d.date && period === 'monthly') {
      const time = moment(d.date).format('DD');
      xValue = parseInt(time, 10);
    }
    return xValue;
  };

  return (
    <SalesReportsContainer>
      <div className="header">
        <img src={back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Sales &amp; reports</div>
      </div>
      <div className="content">
        <PeriodicBar period={period} setPeriod={setPeriod} setChartData={setChartData} />
        <DurationBar period={period} periodParam={periodParam} rangeParam={rangeParam} setRangeParam={setRangeParam} />
        <div className="title">Sales</div>
        <div className="description">
          Gauge the success of your business by comparing sales across pass data, no of order, and weather.
        </div>
        <div className="chart">
          <V.VictoryChart
            domainPadding={chartSettings[period].domainPadding}
            singleQuadrantDomainPadding={{ x: false }}
            padding={{
              top: 8,
              bottom: 22,
              left: 0,
              right: 35,
            }}
            style={{
              background: { fill: '#ffffff' },
            }}
            height={210}
            width={280}
          >
            <V.VictoryAxis
              crossAxis={false}
              domain={chartSettings[period].chartDomain}
              standalone={false}
              style={{
                axis: { stroke: 'none' },
                grid: {
                  fill: 'none',
                  stroke: '#e2e8f0',
                  strokeDasharray: '5, 5',
                  strokeWidth: 1,
                },
                tickLabels: { fontSize: 12 },
              }}
              tickValues={chartSettings[period].tickValues}
              tickCount={chartSettings[period].tickCount}
              tickFormat={(d) => {
                if (chartData && chartData.length === 0) return '';
                if (period === 'daily') {
                  if (d === 0) return '12AM';
                  if (d === 12) return '12PM';
                  if (d === 6 || d === 18) return '6';
                }

                if (period === 'weekly') {
                  const { date } = chartData[d];
                  if (date) return moment(date).format('ddd');
                }
                return d;
              }}
              tickLabelComponent={
                <V.VictoryLabel
                  dy={-5}
                  dx={(d) => {
                    if (period === 'daily') {
                      if (d.index === 0) return 10;
                    }
                    return null;
                  }}
                />
              }
            />
            <V.VictoryAxis
              dependentAxis
              orientation="right"
              offsetX={35}
              style={{
                axis: { stroke: 'none' },
                grid: { fill: 'none', stroke: '#e2e8f0', strokeWidth: 1 },
                tickLabels: { fontSize: 12 },
              }}
              tickCount={3}
              tickFormat={(d) => {
                if (chartData.length === 0) return null;
                return d;
              }}
              tickLabelComponent={<V.VictoryLabel dx={-8} />}
            />
            <V.VictoryBar
              data={chartData}
              barWidth={chartSettings[period].barWidth}
              style={{ data: { fill: '#e2e8f0' } }}
              x={(d) => getXValue(d)}
              y={(d) => (d['sale-history'] ? d['sale-history'] : 0)}
            />
            <V.VictoryLine
              data={chartData}
              style={{
                data: {
                  stroke: '#fec35d',
                  strokeWidth: 2,
                },
              }}
              x={(d) => getXValue(d)}
              y={(d) => {
                const data = d['sale-projection'];
                if (data !== undefined) return data;
                return null;
              }}
            />
          </V.VictoryChart>
        </div>
        <div className="subgroup">
          <div className="subgroup-title">Forecast</div>
          <div className="not-accurate" onClick={() => setShowModal(true)}>
            Not Accurate?
          </div>
          <div className="sales">
            RM
            {summaryData['sale-projection'] ? summaryData['sale-projection'] : 0}
          </div>
          <div className="forecast-subchart">
            <V.VictoryChart height={280} width={1200}>
              <V.VictoryAxis
                crossAxis={false}
                dependentAxis
                style={{
                  axis: { stroke: 'none' },
                  grid: { fill: 'none' },
                  tickLabels: { fill: 'none' },
                }}
              />
              <V.VictoryLine
                data={chartData}
                // x={(d) => getXValue(d)}
                y={(d) => {
                  const data = d['sale-projection'];
                  if (data !== undefined) return data;
                  return null;
                }}
                style={{
                  data: { stroke: '#fec35d', strokeWidth: 15 },
                }}
              />
            </V.VictoryChart>
          </div>
          <div className="text">Sales over time</div>
          <div className="orders">{summaryData['order-projection'] ? summaryData['order-projection'] : 0} orders</div>
        </div>
        <div className="subgroup">
          <div className="subgroup-title">Past data</div>
          <div />
          <div className="sales">
            RM
            {summaryData['sale-history'] ? summaryData['sale-history'] : 0}
          </div>
          <div className="past-data-subchart">
            <V.VictoryChart height={280} width={1200}>
              <V.VictoryAxis
                crossAxis={false}
                dependentAxis
                style={{
                  axis: { stroke: 'none' },
                  grid: { fill: 'none' },
                  tickLabels: { fill: 'none' },
                }}
              />
              <V.VictoryBar
                data={chartData}
                // x={(d) => getXValue(d)}
                y={(d) => (d['sale-history'] ? d['sale-history'] : 0)}
                barWidth={period === 'weekly' ? 130 : 30}
                style={{ data: { fill: '#e2e8f0' } }}
              />
            </V.VictoryChart>
          </div>
          <div className="text">Sales over time</div>
          <div className="orders">{summaryData['order-history'] ? summaryData['order-history'] : 0} orders</div>
        </div>
      </div>
      <Modal onClose={() => setShowModal(false)} open={showModal}>
        <NotAccurateModal />
      </Modal>
    </SalesReportsContainer>
  );
}

export default withAuth(SalesReports);
