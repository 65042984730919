import React from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../components';
import Details from './Details';
import SignupContainer from './index.style';

function SignUp() {
  const [form, setForm] = React.useState('signup');
  const [user, setUser] = React.useState({
    name: '',
    email: '',
    password: '',
    companyName: '',
    role: '',
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setForm('details');
  };

  switch (form) {
    case 'details':
      return <Details user={user} setUser={setUser} handleChange={handleChange} setForm={setForm} />;
    default:
      return (
        <SignupContainer>
          <form className="signup-form" onSubmit={onSubmit}>
            <div className="title">FORECAS+</div>
            <div className="desc">
              <span>Estimating what your business’s sales are going to be in the future with AI based analytics. </span>
              <Link to="/account/login">Login now.</Link>
            </div>
            <label className="form-input" htmlFor="email">
              <div className="label">Email</div>
              <input
                className="input"
                placeholder="name@email.com"
                type="email"
                id="email"
                autoComplete="email"
                onChange={handleChange}
                value={user.email}
                required
              />
            </label>
            <label className="form-input" htmlFor="password">
              <div className="label">Password</div>
              <input
                className="input"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={handleChange}
                required
              />
            </label>
            <div className="btn-container">
              <CustomButton type="submit" value="CREATE AN ACCOUNT" theme="default" />
            </div>
          </form>
          <Link className="fmh-signup" to="/account/fmh-signup">
            or signup with Food Market Hub
          </Link>
        </SignupContainer>
      );
  }
}

export default SignUp;
