import React from 'react';

import { up, down } from '../../../../assets/icons';
import StyledIncrementableNumBox from './index.style';

function IncrementableNumBox(props) {
  const { className, id, startingValue, incrementalValue, onValueChanged } = props;
  const [value, setValue] = React.useState(startingValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    onValueChanged(id, e.target.value);
  };

  const onUpClick = () => {
    const updatedValue = value + incrementalValue;
    setValue(updatedValue);
    onValueChanged(id, updatedValue);
  };

  const onDownClick = () => {
    const updatedValue = value >= incrementalValue ? value - incrementalValue : 0;
    setValue(updatedValue);
    onValueChanged(id, updatedValue);
  };

  return (
    <div className={className}>
      <StyledIncrementableNumBox key={id}>
        <input className="input" type="number" id={id} value={value} onChange={handleChange} required />
        <div className="control">
          <div className="button" onClick={onUpClick}>
            <img src={up} alt="up" />
          </div>
          <div className="divider" />
          <div className="button" onClick={onDownClick}>
            <img src={down} alt="down" />
          </div>
        </div>
      </StyledIncrementableNumBox>
    </div>
  );
}

export default IncrementableNumBox;
