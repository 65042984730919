import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { storeKitchenItem, dataSummary, sourceStoreMenuItem } from '../../services';
import { icons } from '../../assets';
import { withAuth, UserContext } from '../../helper/utils';
import Add from './Add';
import Detail from './Detail';
import { HoursSelectionBar } from './components';
import { CustomButton } from '../components';
import StyledKitchenItems from './index.style';

function KitchenItems() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const [hours, setHours] = React.useState(1);
  const [kitchenItems, setKitchenItems] = React.useState([]);
  const [menuItems, setMenuItems] = React.useState([]);
  const [kitchenItemsSale, setKitchenItemsSale] = React.useState([]);

  const onBackClick = () => navigate(-1);

  React.useEffect(() => {
    storeKitchenItem(userContext.auth.token, { storeId: userContext.selectedStore._id }).then((res) => {
      setKitchenItems(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (kitchenItems.length > 0) {
      sourceStoreMenuItem(userContext.auth.token, { sourceId: userContext.selectedStore.source._id })
        .then((res) => {
          setMenuItems(res.data);
        })
        .catch(() => {
          const allMItems = kitchenItems.reduce((acc, kItem) => {
            const currentMItems = kItem.menuItems.map((m) => ({ code: m.code, name: m.code }));

            return _.unionWith(acc, currentMItems, _.isEqual);
          }, []);
          setMenuItems(allMItems);
        });
    }
  }, [kitchenItems]);

  React.useEffect(() => {
    if (kitchenItems.length > 0) {
      const data = {
        storeId: userContext.selectedStore._id,
        startDate: moment().startOf('h').toDate(),
        endDate: moment().add(hours, 'h').startOf('h').toDate(),
        durationInterval: 'PT1H',
        conditions: kitchenItems.map((i) => ({
          category: 'KitchenItem',
          dataKeyName: i.name,
          mergeData: true,
          defaultValue: 0,
        })),
      };

      dataSummary(userContext.auth.token, data).then((res) => {
        setKitchenItemsSale(res.data);
      });
    }
  }, [hours, kitchenItems]);

  const onAddKitchenItemClick = () => navigate('/kitchen-items/add');
  const onKitchenItemDetailClick = (name) => navigate(`/kitchen-items/${name}/detail`);

  let sales = [];
  if (kitchenItems.length > 0) {
    sales = kitchenItems.map((k) => {
      const result = {};
      result.name = k.name;
      result.menuItems = k.menuItems.map((i) => i.code).join(', ');
      if (menuItems.length > 0) {
        result.menuItems = menuItems
          .reduce((acc, obj) => {
            const names = acc;
            if (k.menuItems.findIndex((m) => m.code === obj.code) >= 0) names.push(obj.name);
            return names;
          }, [])
          .join(', ');
      }
      result.units = 0;
      result.unitOfMeasurement = k.unitOfMeasurement;
      if (kitchenItemsSale.length > 0) {
        const sumUnits = kitchenItemsSale.reduce((acc, obj) => {
          let s = acc;
          if (obj[k.name] && obj[k.name] > 0) s += obj[k.name];
          return s;
        }, 0);
        result.units = sumUnits;
      }
      return result;
    });
  }

  const renderKitchenItems = sales.map((k) => (
    <div className="kitchen-item" key={k.name} onClick={() => onKitchenItemDetailClick(k.name)}>
      <img src={icons.kitchenItem} className="icon" alt="icon" />
      <div className="description">
        <div className="menu-items">{k.menuItems}</div>
        <div className="name">{k.name}</div>
      </div>
      {k.units <= 0 ? (
        <div className="no-data">No data</div>
      ) : (
        <div className="units-uom">
          <div className="units">{k.units}</div>
          <div className="uom">{k.unitOfMeasurement}</div>
        </div>
      )}
    </div>
  ));
  return (
    <StyledKitchenItems>
      <div className="header">
        <img src={icons.back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Kitchen items</div>
      </div>
      <div className="content">
        {kitchenItems.length > 0 ? (
          <div>
            <HoursSelectionBar hours={hours} setHours={setHours} />
            {renderKitchenItems}
          </div>
        ) : (
          <>
            <div />
            <div className="empty-list">Empty state</div>
          </>
        )}
        <div className="btn-container">
          <CustomButton type="button" value="ADD KITCHEN ITEM" theme="default" onClick={onAddKitchenItemClick} />
        </div>
      </div>
    </StyledKitchenItems>
  );
}

const authKitchenItems = withAuth(KitchenItems);
authKitchenItems.Add = Add;
authKitchenItems.Detail = Detail;

export default authKitchenItems;
export { Add, Detail };
