import styled from 'styled-components';

const AddKitchenItem = styled.div`
  height: 100%;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .close {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .content {
    .form {
      min-height: max(47.2rem, calc(100vh - 5.2rem));
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: #4a5568;

      .input-part {
        > div {
          padding: 2rem;
        }
        .form-input {
          padding-bottom: 1.6rem;
          border-bottom: 0.1rem solid #edf2f7;
          .input {
            width: 28rem;
            height: 3.6rem;
            padding: 0.7rem 1rem;
            margin-top: 0.5rem;
            border: 0.1rem solid #a0aec0;
            border-radius: 0.4rem;
            color: #4a5568;
          }
        }

        .menu-selection-plus {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          color: #2d3748;

          .plus {
            width: 2rem;
            height: 2rem;
          }
        }

        .selected-menu-items {
          margin: 1.5rem 0;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.2rem;
          gap: 0.5rem;

          > div {
            background: #edf2f7;
            border-radius: 0.4rem;
            padding: 0.4rem 0.5rem;
            height: 2.4rem;
          }
        }

        .uom {
          padding-bottom: 0;
          border: none;
        }

        .menu-items-units {
          > div {
            width: 28rem;
            height: 6.7rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            padding: 1.5rem 0;
            border-bottom: 0.1rem solid #edf2f7;

            & > .menu-item {
              width: 18rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            & > .units {
              width: 9rem;
            }
          }
        }
      }

      .btn-container {
        margin: 0 2rem 2rem 2rem;
        width: 28rem;
      }
    }
  }
`;

export default AddKitchenItem;
