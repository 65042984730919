import React from 'react';
import axios from 'axios';
import { icons } from '../../../../assets';
import { sourceStoreMenuItem } from '../../../../services';
import { UserContext } from '../../../../helper/utils';
import StyledMenuSelection from './index.style';

function MenuSelection(props) {
  const { onClose, selectedMenuItems, setSelectedMenuItems } = props;
  const [menuItems, setMenuItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    if (selectedMenuItems.length > 0) {
      setMenuItems(selectedMenuItems);
      setIsLoading(false);
    } else {
      sourceStoreMenuItem(userContext.auth.token, { sourceId: userContext.selectedStore.source._id }).then((res) => {
        if (!unmounted) {
          const result = res.data;
          const mItems = result.map((s) => ({ ...s, checked: false }));
          setMenuItems(mItems);
          setIsLoading(false);
        }
      });
    }
    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, []);

  const onCloseClick = () => onClose();

  const handleOnChange = (code) => {
    const updatedMenuItems = menuItems.map((m) => (m.code === code ? { ...m, checked: !m.checked } : m));
    setMenuItems(updatedMenuItems);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSelectedMenuItems(menuItems);
    onClose();
  };

  const renderCheckboxList = menuItems.map((m) => (
    <div className="menu-item" key={m.code}>
      <label htmlFor={m.code}>
        <div className="checkbox">
          <input
            type="checkbox"
            id={m.code}
            name={m.name}
            value={m.code}
            checked={m.checked}
            onChange={() => handleOnChange(m.code)}
          />
          <div className="checkmark" />
        </div>
        <div className="name">{m.name}</div>
      </label>
    </div>
  ));

  return (
    <StyledMenuSelection>
      <div className="header">
        <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
        <div className="title">Menu selection</div>
      </div>
      <div className="content">
        {isLoading ? (
          <div className="loader" />
        ) : (
          <form className="menu-selection-form" onSubmit={onSubmit}>
            {renderCheckboxList}
            <input className="submit-btn" type="submit" value="CONTINUE" />
          </form>
        )}
      </div>
    </StyledMenuSelection>
  );
}

export default MenuSelection;
