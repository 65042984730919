import React from 'react';

import Snackbar from './index.style';

function SnackBar(props) {
  const { open, onClose, autoHideDuration, snackBarDetails } = props;

  React.useEffect(() => {
    if (open) {
      setTimeout(() => onClose(), autoHideDuration);
    }
  }, [open]);

  return (
    <Snackbar open={open}>
      <img className="snackbar-icon" src={snackBarDetails.icon} alt="icon" />
      <div>{snackBarDetails.text}</div>
    </Snackbar>
  );
}

export default SnackBar;
