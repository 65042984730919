import styled from 'styled-components';

const StoreSettingsContainer = styled.div`
  height: 100vh;
  width: 100%;

  ${(props) => (props.openModal ? `overflow: hidden;` : '')}

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .store-settings-form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .sub-title {
      color: #2d3748;
      font-weight: bold;
    }

    .form-input {
      margin: 1.5rem auto 0 auto;
      width: 28rem;
      color: #4a5568;
    }

    .form-input > .input {
      width: 28rem;
      margin-top: 0.5rem;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      color: #4a5568;
      padding: 0.7rem 1rem;
    }

    .form-input > .multi {
      height: 6.4rem;
      resize: none;
      box-sizing: border-box;
      vertical-align: top;
    }

    .location-header {
      width: 28rem;
      height: 2rem;
      margin: 2rem auto 0.5rem auto; // 4px textarea created
      font-weight: bold;
      color: #2d3748;
    }

    .location-detail {
      display: flex;
      padding: 1.5rem 0;
      align-items: center;
      border-bottom: 0.1rem solid #edf2f7;
      color: #4a5568;

      .locate {
        height: 2rem;
        width: 2rem;
      }

      .name {
        height: 2rem;
        width: 22rem;
        margin-left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .forward {
        height: 2rem;
        width: 2rem;
        margin-left: 1rem;
      }
    }

    .location-desc {
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #718096;
    }

    .members-header {
      width: 28rem;
      height: 2rem;
      margin: 2rem auto 0.5rem auto; // 4px textarea created
      font-weight: bold;
      color: #2d3748;
    }

    .member-list-container {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;

      .member-container {
        display: flex;
        flex-direction: row;
        margin: 1rem 0 1.4rem 0;

        img {
          width: 5rem;
          margin: 0 2rem 0 0;

          &.hide {
            visibility: hidden;
          }
        }

        .member-details {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 75%;

          div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      hr {
        opacity: 0.1;
      }
    }

    .bottom-btn-container {
      margin-top: max(0rem, calc(100vh - 63.3rem));

      .btn-container {
        width: 28rem;
        margin: auto;
        margin-top: 0;
      }

      .delete-btn {
        text-align: center;
        color: #bf411a;
        width: 18rem;
        margin: 1rem auto 0 auto;
      }
    }
  }

  .modal-container {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Black w/ opacity */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    opacity: 1;
    &.modal-container-display {
      display: flex !important;
    }
  }

  .modal-content {
    position: absolute;
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    width: 28rem;
    border-radius: 0.8rem;
    margin: auto;
    padding: 2rem;
    z-index: 99;
    left: 0;
    right: 0;
    top: 10rem;

    &.modal-confirm-display {
      display: flex !important;
    }

    .modal-title {
      font-weight: bold;
      margin: 0 0 0.6rem 0;
    }
  }
`;

export default StoreSettingsContainer;
