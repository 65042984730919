import React from 'react';
import { Link } from 'react-router-dom';

import NotAccurateModalContainer from './index.style';

function NotAccurateModal() {
  return (
    <NotAccurateModalContainer>
      <div className="modal-title">Not Accurate?</div>
      <div className="modal-body">Help us to improve the accuracy</div>
      <Link className="link-btn" to="/event/add">
        Tell us what happened?
      </Link>
    </NotAccurateModalContainer>
  );
}

export default NotAccurateModal;
