import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { forgetPassword } from '../../../services';
import { checkmark, alert, back } from '../../../assets/icons';
import { SnackBar, CustomButton } from '../../components';

import ForgetPasswordContainer from './index.style';

function ForgetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = React.useState();
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const onBackClick = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const resetPasswordUrl = `${window.location.href.replace(location.pathname, '')}/account/new-password?token=`;
    forgetPassword({ resetPasswordUrl, email })
      .then((res) => {
        if (res.status) {
          setSnackBarDetails({ text: 'Got it! An email is on the way.', icon: checkmark });
          setOpenSnackBar(true);
        }
      })
      .catch(() => {
        setSnackBarDetails({ text: 'Email does not exist.', icon: alert });
        setOpenSnackBar(true);
      });
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <ForgetPasswordContainer>
      <div className="header">
        <img src={back} className="back" alt="back" onClick={onBackClick} />
        <div className="title">Forget Password</div>
      </div>
      <form className="forget-password-form" onSubmit={onSubmit}>
        <label className="form-input" htmlFor="name">
          <div className="label">Email</div>
          <input className="input" type="email" id="email" onChange={handleChange} value={email} required />
        </label>
        <div className="btn">
          <CustomButton type="submit" value="REQUEST NEW PASSWORD" theme="default" />
        </div>
      </form>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </ForgetPasswordContainer>
  );
}

export default ForgetPassword;
