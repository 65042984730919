import styled from 'styled-components';

const KitchenItems = styled.div`
  height: 100%;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .content {
    min-height: max(47.2rem, calc(100vh - 5.2rem));
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .empty-list {
      color: #000000;
      font-weight: bold;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .kitchen-item {
      margin-top: 2rem;
      width: 28rem;
      height: 5.6rem;
      border-bottom: 0.1rem solid #edf2f7;
      display: flex;
      color: #2d3748;

      .icon {
        width: 4rem;
        height: 4rem;
      }

      .description {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        width: 16.3rem;

        .menu-items {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 1.6rem;
          font-size: 1.2rem;
          color: #a0aec0;
        }
      }

      .no-data {
        text-align: center;
        height: 4rem;
        width: 4rem;
        margin-left: 2.7rem;
        color: #212b36;
      }

      .units-uom {
        width: 3rem;
        margin-left: 3.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .btn-container {
      margin-top: 2rem;
      width: 28rem;
    }
  }
`;

export default KitchenItems;
