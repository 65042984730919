import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import UserContext from '../../../helper/utils/UserContext';
import { login } from '../../../services';
import { SnackBar, CustomButton } from '../../components';
import { alert } from '../../../assets/icons';

import LoginContainer from './index.style';

function Login() {
  const [account, setAccount] = React.useState({ email: '', password: '' });
  const [searchParams] = useSearchParams();
  const [snackBarDetails, setSnackBarDetails] = React.useState({ text: '', icon: '' });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [redirect, setRedirect] = React.useState('');

  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.id]: e.target.value });
  };

  React.useEffect(() => {
    const url = window.location.href.replace(window.origin + window.location.pathname, '');
    setRedirect(url);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    login(account)
      .then((res) => {
        const { token, userId } = res.data;
        userContext.setAuth({ authenticated: true, token, userId });
        if (searchParams.get('redirect')) {
          navigate(searchParams.get('redirect'));
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        setSnackBarDetails({ text: error.response.data.message, icon: alert });
        setOpenSnackBar(true);
      });
  };

  return (
    <LoginContainer>
      <form className="login-form" onSubmit={submit}>
        <div className="title">FORECAS+</div>
        <div className="desc">
          <span>Estimating what your business’s sales are going to be in the future with AI based analytics. </span>
          <Link to={`/account/signup${redirect}`}>Create account now.</Link>
        </div>
        <label className="form-input" htmlFor="email">
          <div className="label">Email</div>
          <input
            className="input"
            placeholder="name@email.com"
            type="email"
            id="email"
            autoComplete="email"
            onChange={handleChange}
            required
          />
        </label>
        <label className="form-input" htmlFor="password">
          <div className="label">Password</div>
          <input
            className="input"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            required
          />
        </label>
        <div className="btn-container">
          <CustomButton type="submit" value="LOGIN" theme="action" />
        </div>
        <Link className="forget-password" to="/account/forget-password">
          Forget password
        </Link>
      </form>
      <Link className="fmh-login" to="/account/fmh-login">
        or login with Food Market Hub
      </Link>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        snackBarDetails={snackBarDetails}
      />
    </LoginContainer>
  );
}

export default Login;
