import React from 'react';
import DatePicker from 'react-datepicker';
import { calendar } from '../../../assets/icons';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangePicker from './index.style';

function SingleLinedDateRange(props) {
  const { onDateChange, startDate, endDate } = props;

  const onChange = (dates) => {
    onDateChange(dates);
  };

  const DatePickerWithIcon = React.forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker" onClick={onClick}>
      <div className="control">
        <img src={calendar} className="calendar" alt="search" />
        <button type="button" className="date-input" ref={ref}>
          {value}
        </button>
      </div>
    </div>
  ));

  return (
    <DateRangePicker htmlFor="date">
      <div className="content">
        <DatePicker
          id="date"
          dateFormat="yyyy/MM/dd"
          onChange={onChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          customInput={<DatePickerWithIcon />}
        />
      </div>
    </DateRangePicker>
  );
}

export default SingleLinedDateRange;
