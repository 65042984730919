import styled, { css } from 'styled-components';

const arrowContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.6rem;
  width: 4rem;
  background-color: #f7fafc;
  border: solid 0.1rem #edf2f7;

  &.active {
    background-color: #ffffff;
  }
`;

const arrowImg = (direction) => `
  height: 2rem;
  width: 2rem;
${direction === 'left' ? 'transform: scaleX(-1)' : ''}
`;

const DurationBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;

  .arrow-backward-container {
    ${arrowContainer}
    border-radius: 0.4rem 0 0 0.4rem;

    img {
      ${() => arrowImg('left')};
    }
  }

  .arrow-forward-container {
    ${arrowContainer}
    border-radius: 0 0.4rem 0.4rem 0;

    img {
      ${() => arrowImg('right')};
    }
  }
`;

export default DurationBarContainer;
