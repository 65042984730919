import styled from 'styled-components';

const Event = styled.div`
  height: 100%;
  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;
  }

  .back {
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }

  .title {
    margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
    width: 21rem;
    height: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
  }

  .content {
    min-height: max(47.2rem, calc(100vh - 5.2rem));
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .filter {
      position: relative;

      .search {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0.8rem;
        left: 1rem;
      }

      .filter-input {
        width: 28rem;
        height: 3.6rem;
        padding: 0.7rem 1rem 0.7rem 4rem;
        color: #4a5568;
        background: #ffffff;
        border: 0.1rem solid #a0aec0;
        border-radius: 0.4rem;
      }
    }

    .empty-list {
      color: #000000;
      font-weight: bold;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .event {
      margin-top: 0.5rem;
      width: 28rem;
      height: 5.6rem;
      border-bottom: 0.1rem solid #edf2f7;
      color: #2d3748;
      display: flex;
      align-items: center;

      .calendar {
        height: 2rem;
        width: 2rem;
      }

      .description {
        margin-left: 2rem;
        width: 24rem;
        .remark {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 1.6rem;
          font-size: 1.2rem;
          color: #a0aec0;
        }
      }
    }

    .add-btn {
      margin-top: 2rem;
      width: 28rem;
    }
  }
`;

export default Event;
