import axios from 'axios';

const endpoint = process.env.REACT_APP_BACKEND_API;
const api = axios.create({
  baseURL: '', // Define base url for the http calls
});

const apiResponse = (response) => {
  if (response) {
    if (response.data) {
      return response;
    }
  }
  return [];
};
const ApiCall = async (method, path, data, token = '', contentType = 'application/json') => {
  let call;

  const config = {
    headers: {
      'Content-type': contentType,
      'auth-token': token,
    },
  };

  if (method === 'POST') {
    call = await api.post(endpoint + path, data, config).then((response) => apiResponse(response));
  } else if (method === 'GET') {
    call = await api
      .get(endpoint + path, { headers: { ...config.headers }, params: { ...data } })
      .then((response) => apiResponse(response));
  } else if (method === 'PATCH') {
    call = await api.patch(endpoint + path, data, config).then((response) => apiResponse(response));
  } else if (method === 'DELETE') {
    call = await api.delete(endpoint + path, config).then((response) => apiResponse(response));
  }
  return call;
};

export default ApiCall;
