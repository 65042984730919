import styled from 'styled-components';

const NewPasswordContainer = styled.div`
  height: 100%;

  .header {
    display: flex;
    justify-content: center;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .back {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .new-password-form {
    padding: 2rem;

    .form-input {
      margin: 1.5rem auto 0 auto;
      width: 28rem;
      color: #4a5568;

      div:nth-child(2) {
        margin: 2rem 0 0 0;
      }
    }

    .form-input > .input {
      width: 28rem;
      margin-top: 0.5rem;
      height: 3.6rem;
      border: 0.1rem solid #a0aec0;
      border-radius: 0.4rem;
      color: #4a5568;
      padding: 0.7rem 1rem;
    }

    .btn-container {
      width: 28rem;
      margin-top: max(26.5rem, calc(100vh - 25.9rem));
    }

    .sign-out {
      display: inline-block;
      text-align: center;
      color: #bf411a;
      width: 100%;
      margin: 1rem auto 0 auto;
      text-decoration-line: none;
    }
  }
`;

export default NewPasswordContainer;
