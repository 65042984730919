import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../helper/utils';
import { icons } from '../../../assets';
import { createStoreKitchenItem } from '../../../services';
import { Modal, CustomButton } from '../../components';
import MenuSelection from '../components/MenuSelection';
import IncrementableNumBox from '../components/IncrementableNumBox';
import AddKitchenItem from './index.style';

const uomInitialValue = 0.1;

function Add() {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);
  const [kitchenItem, setKitchenItem] = React.useState({ name: '', unitOfMeasurement: 'svgs' });
  const [showMenuSelectionModal, setShowMenuSelectionModal] = React.useState(false);
  const [selectedMenuItems, setSelectedMenuItems] = React.useState([]);

  const onCloseClick = () => navigate(-1);
  const handleChange = (e) => setKitchenItem({ ...kitchenItem, [e.target.id]: e.target.value });
  const onKitchenItemUnitsChanges = (code, value) => {
    const updatedMenuItems = selectedMenuItems.map((m) => (m.code === code ? { ...m, units: value } : m));
    setSelectedMenuItems(updatedMenuItems);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const menuItems = selectedMenuItems
      .filter((m) => m.checked)
      .map((m) => ({ code: m.code, units: m.units ? m.units : uomInitialValue }));

    if (menuItems.length > 0) {
      const updatedKitchenItem = { ...kitchenItem, storeId: userContext.selectedStore._id, menuItems };
      setKitchenItem(updatedKitchenItem);
      createStoreKitchenItem(userContext.auth.token, updatedKitchenItem).then(() => {
        navigate(-1);
      });
    }
  };

  return (
    <AddKitchenItem>
      <div className="header">
        <img src={icons.close} className="close" alt="close" onClick={onCloseClick} />
        <div className="title">Add Kitchen item</div>
      </div>
      <div className="content">
        <form className="form" onSubmit={onSubmit}>
          <div className="input-part">
            <div className="form-input">
              <label htmlFor="name">
                <div className="label">Item name</div>
                <input
                  className="input"
                  type="text"
                  id="name"
                  value={kitchenItem.name}
                  onChange={handleChange}
                  placeholder="i.e. Tomato sauce"
                  required
                />
              </label>
            </div>
            <div>
              <div className="menu-selection-plus" onClick={() => setShowMenuSelectionModal(true)}>
                <div>Menu selection</div>
                <img src={icons.plus} className="plus" alt="plus" />
              </div>
              <div className="selected-menu-items">
                {selectedMenuItems
                  .filter((m) => m.checked)
                  .map((m) => (
                    <div>{m.name}</div>
                  ))}
              </div>
              <div className="form-input uom">
                <label htmlFor="unitOfMeasurement">
                  <div className="label">Unit of measurement</div>
                  <input
                    className="input"
                    type="text"
                    id="unitOfMeasurement"
                    value={kitchenItem.unitOfMeasurement}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="menu-items-units">
                {selectedMenuItems
                  .filter((m) => m.checked)
                  .map((m) => (
                    <div key={m.code}>
                      <div className="menu-item">{m.name}</div>
                      <IncrementableNumBox
                        id={m.code}
                        startingValue={uomInitialValue}
                        incrementalValue={0.1}
                        onValueChanged={onKitchenItemUnitsChanges}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="btn-container">
            <CustomButton
              type="submit"
              value="ADD KITCHEN ITEM"
              theme="default"
              disabled={
                selectedMenuItems.filter((m) => m.checked).length <= 0 ||
                !kitchenItem.name ||
                !kitchenItem.unitOfMeasurement
              }
            />
          </div>
        </form>
      </div>
      <Modal onClose={() => setShowMenuSelectionModal(false)} open={showMenuSelectionModal}>
        <MenuSelection
          onClose={() => setShowMenuSelectionModal(false)}
          selectedMenuItems={selectedMenuItems}
          setSelectedMenuItems={setSelectedMenuItems}
        />
      </Modal>
    </AddKitchenItem>
  );
}

export default Add;
