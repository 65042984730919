import styled from 'styled-components';

const NotAccurateContainer = styled.div`
  width: 28rem;
  height: 13.5rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  overflow: auto;

  .modal-title {
    width: 24rem;
    height: 2rem;
    font-weight: bold;
    color: #2d3748;
  }

  .modal-body {
    width: 24rem;
    margin-top: 0.5rem;
    color: #4a5568;
  }

  .link-btn {
    display: inline-block;
    margin-top: 1rem;
    color: #1f72c4;
    text-decoration-line: none;
  }
`;

export default NotAccurateContainer;
