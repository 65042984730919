import ApiCall from './api';

export const login = (data) => ApiCall('POST', '/user/login', data);

export const signup = (data) => ApiCall('POST', '/user', data);

export const verifyEmail = (data = {}) => ApiCall('POST', '/user/verify-email', data);

export const resendEmailVerification = (data = {}) => ApiCall('POST', '/user/resend-email-verification', data);

export const forgetPassword = (data = {}) => ApiCall('POST', '/user/forget-password', data, '');

export const newPassword = (data = {}) => ApiCall('POST', '/user/new-password', data);

export const authenticate = (token, data = {}) => ApiCall('GET', '/user/authenticate', data, token);

export const refreshToken = (token, data = {}) => ApiCall('GET', '/user/refresh-token', data, token);

export const addEvent = (token, data = {}) => ApiCall('POST', '/event', data, token);

export const listEvent = (token, data = {}) => ApiCall('GET', '/event', data, token);

export const getEventById = (token, id) => ApiCall('GET', `/event/${id}`, {}, token);

export const updateEvent = (token, id, data) => ApiCall('PATCH', `/event/${id}`, data, token);

export const deleteEvent = (token, id) => ApiCall('DELETE', `/event/${id}`, {}, token);

export const addEventDateRange = (token, id, data = {}) => ApiCall('POST', `/event/${id}/date-range`, data, token);

export const listEventDateRange = (token, id) => ApiCall('GET', `/event/${id}/date-range`, {}, token);

export const deleteEventDateRange = (token, id, dateRangeId) =>
  ApiCall('DELETE', `/event/${id}/date-range/${dateRangeId}`, {}, token);

export const sourceData = (token, data, contentType) => ApiCall('POST', '/source/data', data, token, contentType);

export const sourceStoreMenuItem = (token, data = {}) => ApiCall('GET', '/source/store/menu-item', data, token);

export const sourceStoreService = (token, data = {}) => ApiCall('GET', '/source/store', data, token);

export const sourcePatchService = (token, id, data) => ApiCall('PATCH', `/source/${id}`, data, token);

export const inviteMembers = (token, data = {}) => ApiCall('POST', '/store/invite-members', data, token);

export const verifyInvite = (token, data = {}) => ApiCall('POST', '/store/verify-invite', data, token);

export const storeGetMember = (token, id, data = {}) => ApiCall('GET', `/store/store-members/${id}`, data, token);

export const storeByIdGetService = (token, id) => ApiCall('GET', `/store/${id}`, {}, token);

export const storeGetService = (token, data = {}) => ApiCall('GET', '/store', data, token);

export const storePostService = (token, data = {}) => ApiCall('POST', '/store', data, token);

export const storeDeleteService = (token, id) => ApiCall('DELETE', `/store/${id}`, {}, token);

export const storePatchService = (token, id, data) => ApiCall('PATCH', `/store/${id}`, data, token);

export const createStoreKitchenItem = (token, data = {}) => ApiCall('POST', '/store/kitchen-item', data, token);

export const storeKitchenItem = (token, data = {}) => ApiCall('GET', '/store/kitchen-item', data, token);

export const listInventory = (token, data) => ApiCall('GET', '/store/inventory', data, token);

export const listInventoryUsage = (token, data) => ApiCall('GET', '/store/inventory/usage', data, token);

export const uploadInventoryList = (token, data, contentType) =>
  ApiCall('POST', '/store/inventory/data', data, token, contentType);

export const dataSummary = (token, data = {}) => ApiCall('POST', '/data/summary', data, token);

export const addDataSummary = (token, data = {}) => ApiCall('POST', '/data/summary/add-data', data, token);

export const getSummaryKey = (token, data = {}) => ApiCall('GET', '/data/summary-key', data, token);
