import styled from 'styled-components';

const StoreContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  ${(props) => (props.showSideMenu ? `overflow: hidden; height: 100vh;` : '')}

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;

    .menu {
      height: 2rem;
      width: 2rem;
    }

    .store-name {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .hidden {
    display: none;
  }

  .invisible {
    visibility: hidden;
  }

  .not-enough-data-message {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 28rem;
    height: 13rem;
    color: #745e42;
    background: #fff;
    border: 0.1rem solid #d5c9b9;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .info {
      height: 2rem;
      width: 2rem;
      margin-left: 1.5rem;
      margin-top: 1.5rem;
    }

    .message {
      margin: 1.5rem 1.5rem 1.5rem 1rem;

      p .highlight {
        font-weight: bold;
      }
    }
  }

  .group-title {
    height: 2.8rem;
    width: 28rem;
    margin-top: 2rem;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 2.8rem;
    color: #2d3748;
  }

  .first-stats {
    margin-top: 1rem;
  }

  .forecast-subgroup {
    position: relative;
    width: 28rem;
    margin-top: 1.5rem;
    padding: 2rem 1.5rem;
    background: #ffffff;
    border-radius: 1rem;
    text-align: left;

    .subgroup-header {
      display: flex;
      height: 2rem;
      align-items: center;

      .title-icon {
        height: 2rem;
        width: 2rem;
      }

      .title {
        margin-left: 1rem;
        width: 15rem;
        font-weight: bold;
        color: #4a5568;
      }

      .current-time {
        width: 6.4rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: bold;
        align-self: flex-end;
        color: #4a5568;
        text-align: center;
      }
    }

    .forecast-fields {
      margin-top: 1.5rem;
      width: 19.2rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #718096;
      display: flex;
      justify-content: space-between;

      div {
        width: 9.1rem;
        height: 1.6rem;
      }
    }

    .sale-order {
      width: 18.1rem;
      color: #212b36;
      display: flex;
      justify-content: space-between;

      div {
        width: 8rem;

        span {
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #a0aec0;
          vertical-align: text-bottom;
        }
      }
    }

    .no-forecast-msg {
      margin-left: 3rem;
    }

    .expected-data {
      color: #acb8c8;
      font-size: 1.5rem;
      margin: 1rem 0 0 3rem;
    }

    .chart {
      position: absolute;
      top: 5.5rem;
      right: 1.5rem;
      height: 4.1rem;
      width: 5.8rem;
    }

    .buffer-5px {
      height: 0.5rem;
    }

    .buffer-28px {
      height: 2.8rem;
    }

    .top-5-kitchen-items {
      .kitchen-item {
        margin-top: 1rem;

        .name {
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #4a5568;
        }

        .stats {
          margin-top: 0.4rem;
          display: flex;
          justify-content: space-between;
          height: 2rem;

          .units-uom {
            display: flex;
            align-items: flex-end;

            .units {
              color: #4a5568;
              font-weight: bold;
            }

            .uom {
              margin-left: 0.5rem;
              font-size: 1.2rem;
              line-height: 1.6rem;
              color: #a0aec0;
            }
          }

          .icon-growth {
            display: flex;
            align-items: center;

            .icon {
              height: 2rem;
              width: 2rem;
            }

            .growth {
              margin-left: 0.5rem;
              width: 3.3rem;
              color: #212b36;
            }
          }
        }
      }

      .kitchen-item:first-child {
        margin-top: 2rem;
      }
    }
  }

  .procurement {
    display: flex;
    align-items: center;
    height: 5rem;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
    background: #ffffff;
    border-radius: 1rem;

    .procurement-icon {
      width: 2rem;
      height: 2rem;
    }

    .procurement-text {
      margin: auto 0.5rem auto 1rem;
      color: #4a5568;
    }
  }

  .feedback {
    height: 13.1rem;
    width: 28rem;
    margin-top: 2rem;
  }

  .settings {
    width: 28rem;
    margin-top: 1.5rem;
    background: #ffffff;
    border-radius: 1rem;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      width: 25rem;
      height: 5.1rem;
      border-bottom: 0.1rem solid #edf2f7;
      display: flex;
      align-items: center;

      .settings-icon {
        width: 2rem;
        height: 2rem;
      }

      .settings-text {
        width: 19.5rem;
        margin: auto 0.5rem auto 1rem;
        color: #4a5568;
      }
    }

    div:last-child {
      height: 5rem;
      border: none;
    }
  }
`;

const SideBarContainer = styled.div`
  .sidebar-backdrop {
    position: fixed;
    background: rgba(26, 32, 44, 0.5);
    backdrop-filter: blur(0.5rem);
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
  }

  .sidebar-backdrop.active {
    left: 0;
  }

  .sidebar {
    position: absolute;
    background: #ffffff;
    height: max(52.4rem, 100vh);
    width: 25rem;
    top: 0;
    left: -25rem;
    transition: 850ms;

    .title {
      margin: 2rem 2rem 0 2rem;
      width: 21rem;
      font-size: 2.2rem;
      line-height: 2.8rem;
      font-weight: bold;
    }

    .filter-store {
      position: relative;
      margin: 1rem 2rem 0 2rem;

      .search {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0.8rem;
        left: 1rem;
      }

      .filter-input {
        width: 21rem;
        height: 3.6rem;
        padding: 0.7rem 1rem 0.7rem 4rem;
        color: #4a5568;
        border: 0.1rem solid #a0aec0;
        border-radius: 0.4rem;
      }
    }

    .stores {
      height: max(26.4rem, calc(100vh - 26rem));
      overflow: auto;
      padding: 0 2rem 2rem 2rem;

      .store {
        width: 21rem;
        height: 3.6rem;
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 0.1rem solid #edf2f7;

        .name {
          width: 16rem;
          color: #4a5568;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ellipsis-icon {
          width: 2rem;
          height: 2rem;
        }
      }

      .store.active {
        .name {
          color: #745e42;
          font-weight: bold;
        }
      }

      .store:last-child {
        border: none;
      }
    }

    .actions {
      height: 16.6rem;
      color: #4a5568;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      border-top: 0.1rem solid #edf2f7;

      div {
        display: flex;
        height: 2rem;
        align-items: center;

        .action-icon {
          width: 2rem;
          height: 2rem;
        }

        .action-text {
          margin-left: 1rem;
        }
      }
    }
  }

  .sidebar.active {
    left: 0;
    transition: 350ms;
  }

  .quick-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    position: fixed;
    background: #ffffff;
    height: 20rem;
    width: 100%;
    bottom: -20rem;
    transition: 500ms;

    &.active {
      bottom: 0;
      transition: 500ms;
    }

    .quick-menu-item {
      display: flex;
      font-size: 1.6rem;

      img {
        width: 2rem;
      }

      span {
        margin-left: 1rem;
      }

      span.alert {
        color: #bf4119;
      }
    }
  }
`;

export { StoreContainer, SideBarContainer };
