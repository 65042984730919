import styled from 'styled-components';

const getButtonMargin = (extraElementCount) => {
  if (extraElementCount < 5)
    return `margin-top: max(${21.9 - extraElementCount * 4.6}rem, calc(100vh - ${30.5 + extraElementCount * 4.6}rem));`;
  return `margin-top: max(2rem, calc(100vh - ${30.5 + extraElementCount * 4.6}rem));`;
};

const InviteContainer = styled.div`
  height: 100vh;
  width: 100%;
  overflow: auto;
  background: #f7fafc;

  .header {
    display: flex;
    height: 5.2rem;
    align-items: center;
    border-bottom: 0.1rem solid #edf2f7;

    .close {
      height: 2rem;
      width: 2rem;
      margin-left: 2rem;
    }

    .title {
      margin-left: max(1.5rem, calc(((100% - 21rem) / 2) - 4rem));
      width: 21rem;
      height: 2rem;
      font-weight: bold;
      text-align: center;
      color: #2d3748;
    }
  }

  .invite-form {
    padding: 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .form-input-container {
      .form-input {
        display: block;
        margin-top: 3rem;
        width: 28rem;
        color: #4a5568;
        .email-list {
          .email-input {
            position: relative;
            margin-top: 1rem;

            .input {
              width: 28rem;
              height: 3.6rem;
              border: 0.1rem solid #a0aec0;
              border-radius: 0.4rem;
              color: #4a5568;
              padding: 0.7rem 1rem;
            }

            .clear-email {
              margin: auto;
              position: absolute;
              color: red;
              bottom: 0;
              top: 0.5rem;
              right: 1rem;
              height: 2rem;
            }
          }

          .email-input:first-child {
            margin-top: 0.5rem;
          }
        }

        & > .input {
          width: 28rem;
          height: 3.6rem;
          margin-top: 0.5rem;
          border: 0.1rem solid #a0aec0;
          border-radius: 0.4rem;
          color: #4a5568;
          padding: 0.7rem 1rem;
          text-overflow: ellipsis;
        }
      }

      .form-input:first-child {
        margin-top: 0;
      }

      .copy-link {
        width: max-content;
        margin-top: 0.5rem;
        color: #1f72c4;
      }
    }

    .btn-container {
      width: 28rem;
      ${(props) => getButtonMargin(props.emailCount)}
    }
  }

  .modal-container {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Black w/ opacity */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    opacity: 1;
    &.modal-container-display {
      display: flex !important;
    }
  }

  .modal-content {
    position: absolute;
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    width: 28rem;
    border-radius: 0.8rem;
    margin: auto;
    padding: 2rem;
    font-size: 1.6rem;
    z-index: 99;
    left: 0;
    right: 0;
    top: 10rem;

    &.modal-confirm-display {
      display: flex !important;
    }

    .modal-title {
      font-weight: bold;
      margin: 0 0 0.6rem 0;
    }
  }
`;

export default InviteContainer;
